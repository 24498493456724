<template>
    <div id="app">
        <Header ref="header_main" />
        <div id="audioPlayers" style="display:none;">
            <div
                v-if="this.isMobile && this.current_session.audio && this.showAudioButton && (logged || isGuest) && this.control_audio"
                style="cursor: pointer;position:absolute;float: left; margin: 55px 10px"
>
                <img src="../public/assets/img/header/audio_on.png" @click="audioPause">
            </div>

            <div
                v-if="this.isMobile && this.current_session.audio && this.showAudioButton && (logged || isGuest) && !this.control_audio"
                style="cursor: pointer;position:absolute;float: left; margin: 55px 10px"
>
                <img src="../public/assets/img/header/audio_off.png" @click="audioPlay">
            </div>

            <div
                v-if="!this.isMobile && this.current_session.audio && this.showAudioButton && (logged || isGuest) && this.control_audio"
                style="cursor: pointer;position:absolute;float: left; margin: 15px 10px"
>
                <img src="../public/assets/img/header/audio_on.png" @click="audioPause">
            </div>

            <div
                v-if="!this.isMobile && this.current_session.audio && this.showAudioButton && (logged || isGuest) && !this.control_audio"
                style="cursor: pointer;position:absolute;float: left; margin: 15px 10px"
>
                <img src="../public/assets/img/header/audio_off.png" @click="audioPlay">
            </div>
        </div>

        <audio id="audioPlay" ref="audioPlay" controls loop="true" hidden>
            <source :src="this.current_session.audio" type="audio/mpeg">
        </audio>
        <video id="myVideoPlayer" style="display: none;" class="video-js vjs-default-skin" controls>
            <source
                ref="videoPlay"
                :src="this.current_session.audio"
                type="application/x-mpegURL"
>
        </video>
        <not-found v-if="notFoundError" />
        <router-view v-else-if="started ||access_before_start.includes($route.name)" ref="main" :key="$route.path"
                     style=""></router-view>
        <CampaignOperator v-else-if="$route.name==='CampaignOperator'" />
        <Campaign v-else-if="$route.name==='Campaign'" />
        <Home v-else-if="$route.name==='Iframe' && !logged && !isGuest" />

        <iframe v-show="iframe_temp" id="iframe_temp" ref="iframe_temp" :style="iframe_temp_css" :src="iframe_temp_url" frameborder="0"
                allow="autoplay;" allowfullscreen="allowfullscreen" scrolling="no"
/>


        <a id="ondeComprar" href="#" data-tobuy-modal="open" data-tobuy-hash="seara" style="display: none;">
            Comprar
        </a>

        <TicketGate ref="ticket-gate" />
        <!--    <AmbientTransition v-if="ambient_transition && (((this.$route.name == 'Museum') || (this.$route.name == 'LockerRoom')) || (ticket_gate_ok))" ref="ambient_transition"></AmbientTransition>-->
        <!--    <AmbientTransition v-if="ambient_transition"     ref="ambient_transition"></AmbientTransition>-->
        <AmbientTransition v-if="show_quiz" ref="ambient_transition" />


        <div v-if="isLms">
            <LmsWebSocket v-if="(logged || isGuest) && nickname && started" :room="chat_room" :participants="chat_participants" />
        </div>
        <div v-else>
            <ChatAdmin v-if="this.switch.websockets && (logged || isGuest) && nickname && started"
                       ref="chat_admin" key="ChatAdmin" style="height: 0;overflow: hidden" interactive="admin"
/>

            <Chat v-if="started && this.switch.websockets && (logged || isGuest) && nickname && chat"
                  ref="chat"
                  key="chat" :style="openChatButton ? { display: 'none !important'} : ''" :max="chat_max" :audio="chat_audio" :room="chat_room"
                  :participants="chat_participants"
                  :interactive="chat_interactive" :locale="this.$i18n.locale"
/>
        </div>

        <Mobile v-if="(logged || isGuest) && this.smartphone_options" id="mobile" ref="mobile" />
        <EasterEgg v-if="easter" id="EasterEgg" ref="EasterEgg" />

        <Camera v-if="camera" id="Camera" ref="Camera" :open_gallery="open_gallery" />
        <Notification v-if="notification" :list="notification_list" />
        <Login v-if="(!logged || isGuest)" ref="LoginModal" />
        <Signup v-if="(isGuest || !logged)" ref="SignupModal" />
        <div v-if="logged">
            <ModalBadgesVue v-for="(badge,keyBadge) in modal_badges" v-show="keyBadge===modal_badges.length-1" :key="keyBadge" ref="moda_badges"
                :badge_index="keyBadge"
                :badge_file="badge.file_url"
                :badge_name="badge.name"
                :badge_text="badge.text"
                :badge_external_url="badge.external_url"
/>
        </div>
        <EmailConfirmation v-if="logged && modal_validacao_" />
        <RememberPassword v-if="!logged && modal_RememberPassword" />
        <ResetPassword v-if="!logged && modal_ResetPassword" />


        <PoliticaDePrivacidade v-if="modal_PoliticaDePrivacidade" />
        <Wizard v-if="modal_Wizard" />
        <Termos ref="TermosModal" />

        <component :is="`Moderator${moderatorComponent}`" v-if="moderatorComponent" />
        <Conteudo v-if="conteudo" />

        <Video v-if="unity_video" :video_url="unity_video" />
        <Modal v-if="modal_" ref="modal" :modal_type="modal_type" :modal_title="modal_title" :modal_text="modal_text"
               :modal_response="modal_response" :modal_yes="modal_yes" :modal_no="modal_no" :modal_btn="modal_btn"
               :modal_iframe_url="modal_iframe_url"
/>
        <ModalIframe v-if="modal_iframe" ref="modal_iframe" :modal_iframe_type="modal_iframe_type" />
        <LiveModal v-if="modal_lives" :title="this.live_title" :live_id="this.live_id" />
        <SessionAccess v-if="session_access" ref="session_access" :mode="session_access_type" />
        <Share ref="shareComponent" />
        <Package v-if="modal_package" />
        <CoinsBuy v-if="modal_coins_buy" ref="coinsBuy" />
        <PackageBuy v-if="modal_package_buy" />
        <GuestBuy v-if="modal_guest_buy" />
        <PackagePayments v-if="modal_package_payments" />
        <Mural ref="muralPosts" />
        <AviaController v-for="(t_id,u_id) in avia" ref="AviaController" :key="u_id" :template_id="t_id"
                        :unity_id="u_id"
/>
        <TranslucentModal v-for="(tModal,iTModal) in translucent_modals" v-show="iTModal===translucent_modals.length-1"
                          :key="tModal.id" :modal-info="tModal"
/>
        <ModalRewards v-if="(logged || isGuest) && modal_rewards" :cta-button="reward_cta_button"
                      :cta-label="reward_cta_label" :message="reward_message"
/>
        <ModalPopupPoints v-if="(logged || isGuest) && popUpPoints" :cta-button="popupInfo.url_cta"
                          :cta-label="popupInfo.label_cta" :message="popupInfo.message"
/>
        <SelfieController v-if="logged || isGuest" ref="selfieControl" />
        <TicketGateModal v-if="modal_ticket_gate" ref="ticketGate" />
        <PackageSingle v-if="modal_package_single" />
        <PrivateMessages v-if="modal_private_messages" ref="pm_messages" />
        <Quest v-if="modal_quest" ref="quest" />
        <RankingModal v-if="showRankingModal && isMiniGameHeader"></RankingModal>

        <div v-if="tutorial" id="tutorial-handler" ref="tutorial-handler" class="modals bgBlack">
            <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" data-response="0"
                 @click="tutorialHandler"
/>
            <div ref="tutorial-handler-content" class="modalGerais">
                <div class="modals__content flex" style="background:transparent">
                    <div class="modals__content--header alignVertical">
                        <a href="#" class="close" data-response="0" @click="tutorialHandler">
                            <img src="assets/icons/modals/x.svg" alt="">
                        </a>
                    </div>
                    <div class="modals__content--body alignVertical"
                         style="padding-top:0px;margin-top:5px;margin-bottom:0px;padding-bottom:10px;max-width:100%"
>
                        <div class="modalGerais__tutorial">
                            <iframe src="https://player.vimeo.com/video/571359162" style="height:400px"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- complaint -->
        <div v-if="complaint" id="complaint-box" ref="complaint-box" class="modals bgBlack">
            <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" data-response="0"
                 @click="complaintOpenHandler"
/>
            <div ref="buy-handler-content" class="modalGerais">
                <div class="modals__content flex flex--column">
                    <div class="modals__content--header alignVertical">
                        <p>{{ $t('denunciar') }}</p>
                        <a ref="btn-close" class="btn-0" @click="complaintOpenHandler">
                            <img src="assets/icons/modals/x.svg" alt="">
                        </a>
                    </div>
                    <div class="modals__content--body alignVertical"
                         :style="'background-image: url(\'' +$url +'assets/icons/modals/biblioteca.png\');'"
>
                        <div class="modalGerais__denunciar">
                            <input id="id_user" ref="id_user" name="id_user" type="hidden">
                            <input id="reason" ref="reason" name="reason" type="hidden">
                            <label class="container alignVertical">{{ $t('app.linguagemOuContedoImprprio') }}
                                <input type="radio" name="complaint-reason" value="Linguagem ou contúdo impróprio"
                                       @change="complaintValueHandler"
>
                                <span class="checkmark" />
                            </label>

                            <label class="container alignVertical">{{ $t('app.mensagensDeDio') }}
                                <input type="radio" name="complaint-reason" value="Mensagens de ódio / Ofensiva"
                                       @change="complaintValueHandler"
>
                                <span class="checkmark" />
                            </label>

                            <label class="container alignVertical">{{ $t('app.spam') }}
                                <input type="radio" name="complaint-reason" value="Spam"
                                       @change="complaintValueHandler"
>
                                <span class="checkmark" />
                            </label>

                            <label class="container alignVertical">{{ $t('app.contaFalsa') }}
                                <input type="radio" name="complaint-reason" value="Conta Falsa"
                                       @change="complaintValueHandler"
>
                                <span class="checkmark" />
                            </label>

                            <label class="container alignVertical">{{ $t('app.outras') }}
                                <input type="radio" name="complaint-reason" value="Outros"
                                       @change="complaintValueHandler"
>
                                <span class="checkmark" />
                            </label>

                            <div class="col-12 flex">
                                <input id="complaint-message" ref="complaint-message" class="text"
                                       name="complaint-message"
                                       :placeholder="$t('app.outroMotivo')" type="text"
>
                            </div>

                            <div class="col-6 flex flex-wrap">
                                <button @click="complaintHandler">
{{ $t('app.enviar') }}
</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- -->

        <!-- webcam photo -->
        <div id="webcam-photo" ref="webcam-photo" style="display:none">
            <div id="webcam-photo-content" ref="webcam-photo-content">
                <div id="webcam-photo-header" ref="webcam-photo-header">
                    {{ $t('app.tireUmaSelfie') }}
                    <a class="fr" href="#" @click="webCamExit">
                        <img :src="$url + 'assets/img/icon_close_box.png'" alt="Fechar" width="16" height="16">
                    </a>
                </div>
                <video id="webcam-photo-video" />
                <canvas id="webcam-photo-canvas" />
                <div id="webcam-btns" ref="webcam-btns">
                    <a id="webcam-photo-capture" href="#" style="display:block;padding: 10px 0"
                       @click="webCamCapture"
>
                        <img :src="$url + 'assets/img/webcam/take_photo.png'" alt="Tirar foto">
                    </a>
                    <!--<button class="button__buttonForm button__blue col-4" id='webcam-photo-capture' v-on:click="webCamCapture">Capturar</button>-->
                    <!--<button class="button__buttonForm button__blue col-4" id='webcam-photo-discard' v-on:click="webCamDiscard">Descartar</button>-->
                    <a id="webcam-photo-discard" href="#" style="padding: 10px 0" @click="webCamDiscard">
                        <img :src="$url + 'assets/img/webcam/discard.png'" alt="Descartar foto" width="53">
                    </a>
                    <!--<button class="button__buttonForm button__blue col-4" id='webcam-photo-save' v-on:click="webCamSave">Salvar</button>-->
                    <a id="webcam-photo-save" href="#" style="padding: 10px 0" @click="webCamSave">
                        <img :src="$url + 'assets/img/webcam/save.png'" alt="Salvar foto">
                    </a>
                    <!--<button class="button__buttonForm button__blue col-4" id='webcam-photo-exit' ">Fechar</button>-->
                </div>
            </div>
        </div>

        <!-- <FileUploader :extensionsArray="allowedExtensions" :allowUpload="true" :useStrictFilter="true" modal-id="thumbFileModalId" v-if="file_uploader" emit-event="thumb::changed"></FileUploader> -->
        <!-- -->
        <Popup :notifications="this.popup_notifications" />

        <Political v-if="political" />
        <Security />

        <!-- loader -->
        <!-- <div id="loader" ref="loader">
                <div id="loader-ani"></div>
            </div> -->
        <Loading v-if="loading" />
        <!-- -->
        <div v-if="!this.logged && !this.isGuest && design.copyright_footer && !isLms && ($route.name!='Campaign' && $route.name!='CampaignOperator')" ref="copyright_footer"
             class="copyright_footer" v-html="design.copyright_footer"
/>
        <div v-else-if="(isLms && getLmsConfigs) && getLmsConfigs.footer
        && ($route.name!='Campaign' && $route.name!='CampaignOperator')" ref="copyright_footer" class="copyright_footer_lms"
>
            <img v-if="getLmsConfigs.footer_logo" :src="getLmsConfigs.footer_logo">
            <img v-else :src="logoUrl">

            <p v-if="getLmsConfigs.footer_text" v-html="getLmsConfigs.footer_text" />
            <p v-else>
&copy; Todos os direitos reservados.
</p>
        </div>
      <HeathMode v-if="this.switch.debug_mode"></HeathMode>
</div>
</template>
<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import Mobile from "@/components/Mobile";
import EasterEgg from "@/components/EasterEgg";
import Camera from "@/components/Camera";
import Notification from "@/components/Notification";
import Modal from "@/components/Modal";
import ModalIframe from "@/components/ModalIframe";
import SessionAccess from "@/components/SessionAccess";
import Chat from "@/components/Chat";
import ChatAdmin from "@/components/Chat";
import ModeratorComponents from "@/components/Moderator";
import Conteudo from "@/components/Conteudo";
import Video from "@/components/Video";
import AmbientTransition from "@/components/AmbientTransition";
import Login from "@/components/login/Login";
import Signup from "@/components/login/Signup";
import ModalBadgesVue from "./components/ModalBadges.vue";
import EmailConfirmation from "@/components/EmailConfirmation";
import RememberPassword from "@/components/RememberPassword";
import ResetPassword from "@/components/ResetPassword";
import PoliticaDePrivacidade from "@/components/PoliticaDePrivacidade";
import Wizard from "@/components/Wizard";
import Termos from "@/components/Termos";
import TicketGate from "@/components/TicketGate";
import ModalRewards from "@/components/ModalRewards";
import ModalPopupPoints from "@/components/ModalPopupPoints";
import LiveModal from "@/components/LiveModal";
import logoDefault from "./assets/img/loading.gif";
import logoLoading from "./assets/img/loading.gif";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import 'vue-select/dist/vue-select.css';
import Popup from "@/components/Notifications/Popup";
import Share from "@/components/Modals/Share";
import Package from "@/components/Package";
import PackageBuy from "@/components/PackageBuy";
import PackagePayments from "@/components/PackagePayments";
import Mural from "@/components/Modals/Mural";
import SelfieController from "@/components/Modals/Posts/SelfieController";
import CoinsBuy from "@/components/Package";
import GuestBuy from "@/components/Package";
import TicketGateModal from "@/components/Modals/TicketGate";
import PackageSingle from "@/components/Modals/PackageSingle";
import PrivateMessages from "@/components/Modals/PrivateMessages";
import Quest from "@/components/Quest/Quest";
import Loading from "@/components/Loading";
import CryptoJS from 'crypto-js';
import LmsWebSocket from "@/components/lms/LmsWebSocket.vue";
import TranslucentModal from "@/components/Modals/TranslucentModal";
import AviaController from "@/components/avia/AviaController";
import Security from "@/components/security/Security";
import Political from "@/components/security/Political";
import HeathMode from "@/components/HeathMode.vue";
// import FileUploader from '@/components/lms/components/FileUploader';
let emitter = require('tiny-emitter/instance');
import Campaign from "@/components/Campaign.vue";
import CampaignOperator from "@/components/CampaignOperator.vue";
const isWebview = require("is-ua-webview");


// import $ from "jquery";
import {insertHtmlWithScript, extractSceneNameFromURL, removeDivById, focusUnity} from "@/helper";
import Vue from "vue";
import Home from "@/components/Home.vue";
import Header from "@/components/Header.vue";
import RankingModal from "@/components/Modals/RankingModal.vue";

export default {
    name: "App",
    props: ['eventName', 'sessionName'],
    computed: {
        ...mapGetters([
            "id", "switch", "token", "email", "logged", "dat", "id_form_actual", "isGuest", "name", "nickname", "age", "email_verified", "age_verified", "birth_day",
            "url_actual", "started", "TotalPoints", "TotalCoins", "TotalBadges", "EventimTickets", "NotificationsList", "UserTickets", "is_streamer", "use_avatar", "avatar_metaverse", "user_picture",
            "sessions", "current_session", "webview", "user_type", "is_guest", "groups", "group_id", "stores", "design",
            'quest', 'header_buttons',
            "checkIOS", "checkMobile", 'LibraryName', "reward_slug", 'smartphone_options', 'UserBadges', 'UserTickets', 'event_id', 'avatar_map', 'avatar_first_session', 'last_local',
            "unity_hash_log", "url_after_registration", "userOrigin", "isMobile", "securityContainer", "maps", "event_map_user_progress", "AllBadges", 'isMiniGameHeader'
        ]),
        ...mapGetters("LMS", ["isLms", "getLms", "getLmsConfigs"]),
        ...mapGetters({
            template_html_loaded: 'TemplateHTML/getHTMLTemplateLoaded',
            template_html: 'TemplateHTML/getHTMLTemplate',
        }),
        headers(){
            return {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                'language': this.$i18n.locale,
                "Authorization": `Bearer ${this.token}`
            }
        },
        cssVars() {
            let buttons_color = this.design?.color?.buttons_color || this.getCookie("design_color_buttons_color") || "#ffffff";
            let buttons_color_2nd = this.design?.color?.buttons_color_2nd || this.getCookie("design_color_buttons_color_2nd") || "#ffffff";
            let buttons_color_rgb = this.hexToRgb(buttons_color);
            let buttons_color_2nd_rgb = this.hexToRgb(buttons_color_2nd);
            return {
                "--buttons_color-r": buttons_color_rgb?buttons_color_rgb.r:255,
                "--buttons_color-g": buttons_color_rgb?buttons_color_rgb.g:255,
                "--buttons_color-b": buttons_color_rgb?buttons_color_rgb.b:255,
                "--buttons_color_2nd-r": buttons_color_2nd_rgb?buttons_color_2nd_rgb.r:255,
                "--buttons_color_2nd-g": buttons_color_2nd_rgb?buttons_color_2nd_rgb.g:255,
                "--buttons_color_2nd-b": buttons_color_2nd_rgb?buttons_color_2nd_rgb.b:255,
                "--buttons_color": buttons_color,
                "--buttons_color_hover": this.hoverColor(buttons_color),
                "--buttons_color_2nd": buttons_color_2nd,
                "--buttons_color_2nd_hover": this.hoverColor(buttons_color_2nd),
                "--fonts_color": this.design?.color?.fonts_color || this.getCookie("design_color_fonts_color") || "#000000",
                "--fonts_color_2nd": this.design?.color?.fonts_color_2nd || this.getCookie("design_color_fonts_color_2nd") || "#000000",
                "--headers_color": this.design?.color?.headers_color || this.getCookie("design_color_headers_color") || "#FFFF",
                "--headers_color_logged": this.design?.color?.headers_color_logged || this.getCookie("design_color_headers_color_logged") || "#FFFF",
                "--chat_bg_color": this.design?.color?.chat_bg_color || this.getCookie("design_color_chat_bg_color") || "#FFFF",
                "--chat_text_color": this.design?.color?.chat_text_color || this.getCookie("design_color_chat_text_color") || "#FFFF",
                "--smartphone_bg_color": this.design?.color?.smartphone_bg_color || this.getCookie("design_color_smartphone_bg_color") || "#FFFF",
                "--smartphone_text_color": this.design?.color?.smartphone_text_color || this.getCookie("design_color_smartphone_text_color") || "#FFFF",
                "--chat_bgheader_color": this.design?.color?.chat_bgheader_color || this.getCookie("design_color_chat_bgheader_color") || "#FFFF",
                "--headers_color_2nd": this.design?.color?.headers_color_2nd || this.getCookie("design_color_headers_color_2nd") || "#FFFF",
                "--highlight_color": this.design?.color?.highlight_color || this.getCookie("design_color_highlight_color") || "#FFFF",
                "--font_size": this.fontSizeReference[
                this.design?.font?.font_size || "medio"
                    ],
                "--footer_on": this.design?.footer_on ? "flex" : "none",
                "--multi_language_background": (this.logged || this.isGuest) ? (this.design?.color?.headers_color_logged || this.getCookie("design_color_headers_color_f") || '#FFFF') : (this.design?.color?.headers_color || this.getCookie("design_color_headers_color") || '#FFFF'),
            };
        },
        logoUrl() {
            if ((localStorage.getItem("eventmap")) && (localStorage.getItem("eventmap") != '')) {
                return this.eventlogo || localStorage.getItem("design_eventlogo") || logoDefault;
            } else
                return this.design?.logo || this.getCookie("design_logo") || logoDefault;
        },
        fontLink() {
            return this.design?.font?.font_link || this.getCookie("design_font_font_link") || this.$url_api + "fonts/Roboto.ttf";
        },
    },
    data() {
        return {
            buttons: {
                camera: "",
                notifications: "",
                radio_on: "",
                radio_off: "",
                menu: "",
                coins: "",
                points: "",
                badges: "",
                library: "",
                logoff: "",
                language: "",
                help: "",
            },
            openPackageAfterRegister: false,
            showTools: false,
            access_before_start: ['Signup', 'Login', 'RememberPassword', 'ResetPassword', 'Home', 'EventMap', 'TermosPage', 'Support', "PublicRanking", "Campaign", "CampaignOperator"],
            access_logged: ["Support", "PoliticaPage", "TermosPage", "Campaign", "CampaignOperator"],
            logo_: null,
            fontSizeReference: {
                pequeno: "12px",
                medio: "14px",
                grande: "16px",
            },
            allowedExtensions: {
                images: ['jpg', 'jpeg', 'gif', 'png', 'tif', 'tiff', 'bmp']
            },
            openChatButton: false,
            modal_PoliticaDePrivacidade: false,
            modal_Wizard: false,
            regulamento: false,
            photo_player: false,
            modal_validacao_: false,
            modal_RememberPassword: false,
            modal_ResetPassword: false,
            modal_package: false,
            modal_package_buy: false,
            modal_guest_buy: false,
            modal_package_payments: false,
            modal_coins_buy: false,
            modal_ticket_gate: false,
            modal_package_single: false,
            modal_private_messages: false,
            chat: false,
            chat_max: 10,
            chat_audio: false,
            chat_participants: false,
            chat_interactive: true,
            tabname: '',
            modal_rewards: false,
            reward_message: '',
            reward_cta_button: '',
            reward_cta_label: '',
            tools: true, //chat_room           : this.$route.name,
            chat_room: "",
            chat_owner: "",
            chat_not_clear: false,
            menu_active: false,
            terms: false,
            easter: false,
            baconmix: false,
            camera: false,
            easterId: 0,
            notification: false,
            notification_list: [],
            notification_request: false,
            notifications_count: 0,
            popup_notifications: 0,
            open_gallery: false,
            modal_: false,
            modal_type: null,
            modal_title: null,
            modal_text: null,
            modal_response: null,
            modal_yes: null,
            modal_no: null,
            modal_btn: null,
            modal_iframe_url: null,
            modal_iframe: false,
            modal_iframe_type: null,
            modal_quest: null,
            session_access_type: 'ask',
            session_access: false,
            interval: null,
            focusUnityInterval: null,
            focusUnity: false,
            timer: false,
            library_title: "",
            windowWidth: window.innerWidth,
            ambient_transition: false,
            show_quiz: false,
            ticket_gate_ok: false,
            iframe_temp: false,
            iframe_temp_url: null,
            iframe_temp_css: null,
            iframe_url: null,
            complaint: false,
            complaint_user_id: 0,
            ticket_actual: 0,
            count_inactivity: 0,
            count_inactivity_move: -1,
            iframecomponent: false,
            tutorial: false,
            live: false,
            age_get_user: false,
            modal_lives: false,
            login_date: null,
            radio: null,
            webcam_video: null,
            modal_badges: [],
            webcam_canvas: null,
            webcam_canvas_context: null,
            webcam_stream: null,
            webcam_type: null,
            switch_avatar_new: false,
            moderatorComponent: false,
            conteudo: false,
            live_title: null,
            live_id: null,
            unity_video: null,
            options: [
                {
                    language: 'Português',
                    icon: '/assets/icons/header/brasil.svg',
                    code: "pt-br",
                    label: "-br",
                    active: true
                },
                {
                    language: 'Inglês',
                    icon: '/assets/icons/header/reino-unido.svg',
                    code: "en",
                    label: "",
                    active: true
                },
                {
                    language: 'Espanhol',
                    icon: '/assets/icons/header/espanha.svg',
                    code: 'es',
                    label: '',
                    active: true
                },
                {
                    language: 'Francês',
                    icon: '/assets/icons/header/french.svg',
                    code: 'fr',
                    label: '',
                    active: true
                }
            ],
            store_active: "vt",
            showAudioButton: false,
            control_audio: false,
            myVideoPlayer: null,
            guest_id: 0,
            ticket_gate_id: null,
            ticket_gate_doesnt_exist: null,
            user_already_have_ticket: null,
            package_id: null,
            package_doesnt_exist: null,
            package_single: null,
            private_messages_friend_id: null,
            quest_id: null,
            quest_step_id: null,
            loading: false,
            loading_unity: null,
            RecurrentPayment: false,
            keyUnityConf: 'b9#xK4tL$pA7mDfR3^gH5@q2oZ8eN6jx',
            popUpPoints: false,
            popupInfo: {
                label_cta: '',
                url_cta: '',
                message: ''
            },
            is_lms_session: false,
            lms_session_name: null,
            html_template_: null,
            html_template_loaded_: false,
            //count_notify_points: 0
            iframe_ready: false,
            translucent_modals: [],
            //count_notify_points: 0
            play_wizard: null,
            avia: {},
            currentAvia: null,
            currentHash: null,
            political: false,
            notFoundError: false,
            showRankingModal: false
        };
    },
    components: {
        "not-found": ()=> import("@/components/pages/404"),
        Header,
        Home,
        Share,
        Mural,
        SelfieController,
        CoinsBuy,
        GuestBuy,
        TicketGateModal,
        Popup,
        PoliticaDePrivacidade,
        Wizard,
        Termos,
        RememberPassword,
        ResetPassword,
        EmailConfirmation,
        Signup,
        ModalBadgesVue,
        Login,
        Mobile,
        EasterEgg,
        Camera,
        Notification,
        Modal,
        ModalIframe,
        SessionAccess,
        Chat,
        ChatAdmin,
        AmbientTransition,
        TicketGate,
        Conteudo,
        Video,
        Package,
        PackageBuy,
        PackagePayments,
        PackageSingle,
        PrivateMessages,
        LiveModal,
        Quest,
        Loading,
        ModalRewards,
        ModalPopupPoints,
        LmsWebSocket,
        TranslucentModal,
        AviaController,
        // FileUploader,
        Security,
        Political,
        Campaign,
        CampaignOperator,
        HeathMode,
        ...ModeratorComponents,
        RankingModal
    },
    methods: {
        async loadManifest(designResponse) {
          try {
            const manifestData = designResponse.data.design;
            this.updateThemeColor(manifestData.color.buttons_color);
            if (!this.isIOS()) {
              this.updateManifest(manifestData);
            }
            this.updateIOSIcons(manifestData.logoFavicon);
          } catch (error) {
            console.error('Failed to load manifest:', error);
          }
        },
        updateThemeColor(color) {
            let themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
            if (themeColorMetaTag) {
                themeColorMetaTag.setAttribute('content', color);
            } else {
                themeColorMetaTag = document.createElement('meta');
                themeColorMetaTag.setAttribute('name', 'theme-color');
                themeColorMetaTag.setAttribute('content', color);
                document.head.appendChild(themeColorMetaTag);
            }
        },
        updateManifest(data) {
            const manifest = {
                id: '/',
                name: this.switch.Nome_Aba,
                short_name: this.switch.Nome_Aba,
                start_url: `${window.location.origin}/`,
                display: 'standalone',
                background_color: data.color.headers_color,
                theme_color: data.color.buttons_color,
                scope: '/',
                icons:[
                    {
                      src: data.logoFavicon,
                      sizes: '144x144',
                      type: 'image/png'
                    },
                    {
                        src: data.logoFavicon,
                        sizes: '192x192',
                        type: 'image/png'
                    },
                    {
                        src: data.logoFavicon,
                        sizes: '512x512',
                        type: 'image/png'
                    },
                    {
                        src: data.logoFavicon, // Você pode usar diferentes URLs se tiver diferentes tamanhos de ícones
                        sizes: '152x152',
                        type: 'image/png'
                    },
                    {
                        src: data.logoFavicon, // Você pode usar diferentes URLs se tiver diferentes tamanhos de ícones
                        sizes: '167x167',
                        type: 'image/png'
                    },
                    {
                        src: data.logoFavicon, // Você pode usar diferentes URLs se tiver diferentes tamanhos de ícones
                        sizes: '180x180',
                        type: 'image/png'
                    }
                ]
            };

            const stringManifest = JSON.stringify(manifest);
            const blob = new Blob([stringManifest], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const manifestLink = document.querySelector('#dynamic-manifest');
            if (manifestLink) {
                manifestLink.href = url;
            } else {
                const link = document.createElement('link');
                link.rel = 'manifest';
                link.href = url;
                link.id = 'dynamic-manifest';
                document.head.appendChild(link);
            }
        },
        isIOS() {
          return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        },
        updateIOSIcons(iconUrl) {
          const sizes = ['152x152', '167x167', '180x180', '144x144'];
          sizes.forEach(size => {
            let link = document.querySelector(`link[rel="apple-touch-icon"][sizes="${size}"]`);
            if (link) {
              link.href = iconUrl;
            } else {
              link = document.createElement('link');
              link.rel = 'apple-touch-icon';
              link.sizes = size;
              link.href = iconUrl;
              document.head.appendChild(link);
            }
          });

          // Meta tag específica para a cor da barra de status no iOS
          let appleStatusBarStyle = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]');
          if (appleStatusBarStyle) {
            appleStatusBarStyle.setAttribute('content', 'black-translucent');
          } else {
            appleStatusBarStyle = document.createElement('meta');
            appleStatusBarStyle.setAttribute('name', 'apple-mobile-web-app-status-bar-style');
            appleStatusBarStyle.setAttribute('content', 'black-translucent');
            document.head.appendChild(appleStatusBarStyle);
          }

          // Meta tag específica para habilitar o modo standalone no iOS
          let appleMobileWebAppCapable = document.querySelector('meta[name="apple-mobile-web-app-capable"]');
          if (appleMobileWebAppCapable) {
            appleMobileWebAppCapable.setAttribute('content', 'yes');
          } else {
            appleMobileWebAppCapable = document.createElement('meta');
            appleMobileWebAppCapable.setAttribute('name', 'apple-mobile-web-app-capable');
            appleMobileWebAppCapable.setAttribute('content', 'yes');
            document.head.appendChild(appleMobileWebAppCapable);
          }
        },
      addTranslucentModal({
                                size = 'lg'/*lg sm*/, title = '', content = '',
                                confirm_text = '', cancel_text = '',
                                can_close = true, can_cancel = true, close_background = true,
                                background = false, with_input = false, input_placeholder = '',
                                input_type = 'text',
                                callback = () => {
                                },
                                callback_cancel = () => {
                                },
                                callback_close = () => {
                                }
                            }) {
            let id;
            if (this.translucent_modals.length === 0)
                id = 0;
            else
                id = this.translucent_modals
                    .reduce((prev, current) => (prev.id > current.id) ? prev : current).id;
            this.translucent_modals.push({
                id: id + 1,
                size, title, content, confirm_text, cancel_text,
                can_close, can_cancel, background, with_input, input_placeholder,
                input_type,
                close_background, callback, callback_cancel, callback_close
            });
        },
        removeTranslucentModal(modal) {
            let index = this.translucent_modals.findIndex(_ => _.id === modal.id);
            if (index !== -1) {
                this.translucent_modals.splice(index, 1);
            }
        },
        createHTMLTemplate(html) {
            let vm = this;
            if (html && !this.isLms) {
                if (
                    (!html.logged_page && !vm.logged) ||
                    (html.logged_page && vm.logged) ||
                    (!html.logged_page && vm.logged)
                ) {
                    this.html_template_ = html;
                    this.html_template_loaded_ = true;
                    insertHtmlWithScript(this.html_template_.html);
                    this.$store.dispatch('TemplateHTML/setHTMLTemplate', html).then(() => {
                        // Set loaded content
                        this.$store.dispatch('TemplateHTML/setHTMLTemplateLoaded', true);
                    });

                } else {
                    removeDivById('vt_template_html')
                    this.$store.dispatch('TemplateHTML/setHTMLTemplateLoaded', false).then(() => {
                        this.$router.push({name: 'Login'});
                    });
                }
            } else {
                removeDivById('vt_template_html')
                this.$store.dispatch('TemplateHTML/setHTMLTemplateLoaded', false);
            }
        },
        showMobileTools() {
            this.showTools = !this.showTools
        },
        checkIsUser() {
            if (this.isGuest && this.switch.login_signup) {
                this.$refs.SignupModal.openModal(true);
            } else if (!this.isGuest) {
                this.exitLocal(this.profileExit)
            }
        },
        toEncrypt(val) {
            // console.log('to encrypt =>', CryptoJS.AES.encrypt(val, this.keyUnityConf).toString())
            return CryptoJS.AES.encrypt(val, this.keyUnityConf).toString()
        },
        toDecrypt(val) {
            let bytes = CryptoJS.AES.decrypt(val, this.keyUnityConf);
            return bytes.toString(CryptoJS.enc.Utf8)
        },
        openFileManager() {
            this.file_uploader = true
            console.log('file uploader =>', this.file_uploader)
        },
        openPackage() {
            if (this.switch.finance_profile_menu) {
                // this.modal_package = true;
                this.modalsHandler();
                this.modal_package = (this.modal_coins_buy) ? false : true;
            }
        },
        allRelatedFriends() {
            const root = this;
            axios
                .get(this.$url_api + "api/AllRelatedFriends", {headers: this.headers})
                .then((response) => {
                    root.$refs["chat"].addRelatedFriend(response.data);
                })
                .catch((error) => {
                    error
                });
        },
        createPlayer() {
            if (this.current_session.audio) {
                let url = new URL(this.current_session.audio)
                if (url.pathname.split('.')[1].indexOf("m3u8") !== -1) {
                    let root = this;
                    window.videojs('myVideoPlayer', {}, function () {
                        root.myVideoPlayer = this;
                        this.showAudioButton = true;
                    });
                } else {
                    this.$refs['audioPlay'].load();
                    this.showAudioButton = true;
                }
            }
        },
        headerButtons(obj) {
            for (let key in obj) {
                if (key == 'coins_button') {
                    obj[key] = obj[key].indexOf('black') !== -1
                        ? obj[key].replace(/black/g, 'white')
                        : obj[key].replace(/<svg/, '<svg fill="white"')
                } else {
                    obj[key] = obj[key].indexOf('black') !== -1
                        ? obj[key].replace(/black/g, 'var(--fonts_color)')
                        : obj[key].replace(/<svg/, '<svg fill="var(--fonts_color)"')
                }

                obj[key] = obj[key].indexOf('width') !== -1
                    ? obj[key].replace(/width="(\d{1,})"/g, 'width="$1"')
                    : obj[key].replace(/<svg/, '<svg width="20"')

                obj[key] = obj[key].indexOf('height') !== -1
                    ? obj[key].replace(/height="(\d{1,})"/g, 'height="$1"')
                    : obj[key].replace(/<svg/, '<svg height="20"')
            }
            this.update_header_buttons(obj)
        },
        audioPlay() {
            this.control_audio = true;
            if (this.myVideoPlayer) {
                this.myVideoPlayer.play();
            } else {
                this.$refs['audioPlay'].play();
            }
        },
        audioPause() {
            this.control_audio = false;
            if (this.myVideoPlayer) {
                this.myVideoPlayer.pause();
            } else {
                this.$refs['audioPlay'].pause();
            }
        },

        logoHandler() {
            if (localStorage.getItem("eventmap")) {
                this.logo_ = this.eventlogo || this.design?.logo || this.getCookie("design_logo") || logoDefault;
                this.logoFavicon_ = this.faviconLogo || this.design?.logoFavicon || this.getCookie("design_logo_favicon") || logoDefault;
            } else {
                this.logo_ = this.design?.logo || this.getCookie("design_logo") || logoDefault;
                this.logoFavicon_ = this.design?.logoFavicon || this.getCookie("design_logo_favicon") || logoDefault;
            }
            this.changeFavicon();
        },
        conteudoHandler(e) {
            if (e) e.preventDefault();
            //console.log'conteudo');
            this.analytics('library_btn')
            this.conteudo = true;
        },
        //----- moderator -----//
        moderatorHandler(type = "Group") {
            this.moderatorComponent = type;
        },
        groupHandler() {
            //console.log'group_id => ', this.group_id, this.user_type);

            /*
                let session_group_ = [];
                for(let o in this.sessions)
                {
                    if (this.sessions[o].groups)
                    {
                        session_group_.push(this.sessions[o].name);
                    }
                }
                */
            let o;
            let id_room_;
            //console.log'groups => ', this.group_id, this.groups, ' _end');
            for (o in this.groups) {
                if ((this.groups[o].on) && (String(this.groups[o].id) == String(this.group_id))) {
                    id_room_ = this.groups[o].id_room;
                    break;
                }
            }
            //console.log'id_room_ => ', id_room_);
            for (o in this.sessions) {
                //if ((this.sessions[o].groups) && (this.sessions[o].room_id == id_room_) && (this.$route.path != ( '/e/' + this.sessions[o].name))) {
                if ((this.sessions[o].room_id == id_room_) && (this.$route.path != ('/e/' + this.sessions[o].name))) {
                    if ((this.group_id) && (this.user_type == 'participant')) {
                        //this.$router.push({path: ( '/e/' + this.sessions[o].name)});
                        window.location.href = this.$url + '#/e/' + this.sessions[o].name;
                    }
                    break;
                }
            }
        },
        //----- moderator -----//
        //----- webcam-photo -----//
        webCamExit(e) {
            e;
            this.webcam_stream.getTracks().forEach((track) => track.stop());
            this.webcam_video.srcObject = null;

            try {
                this.webcam_canvas_context.clearRect(
                    0,
                    0,
                    this.webcam_canvas.width,
                    this.webcam_canvas.height
                );
            } catch (e) {
                e;
            }
            document.getElementById("webcam-photo").style.display = "none";
            document.getElementById("webcam-photo-discard").style.display = "none";
            document.getElementById("webcam-photo-save").style.display = "none";
            document.getElementById("webcam-photo-capture").style.display = "block";
        },
        webCamStart(webcam_type_) {
            this.webcam_type = webcam_type_;
            let root_ = this;

            navigator.mediaDevices
                .getUserMedia({video: true})
                .then(function (mediaStream) {
                    root_.webcam_stream = mediaStream;
                    root_.webcam_video = document.querySelector("#webcam-photo-video");
                    root_.webcam_video.srcObject = mediaStream;
                    root_.webcam_video.play();

                    document.getElementById("webcam-photo").style.display = "block";
                })
                .catch(function (err) {
                    err;
                    //console.log"Não há permissões para acessar a webcam.");
                });
        },
        getSetupWithPromise() {
            let $this = this;
            return new Promise((resolve, reject) => {
                axios
                    .get(this.$url_api + "api/setup", {
                        headers: {'language': this.$i18n.locale,},
                        params: {
                            event: localStorage.getItem("eventmap"),
                            slug_page: extractSceneNameFromURL(window.location.href)
                        }
                    })
                    .then((response) => {
                        if(response.data.libras_enable === 1) {
                            const vlibrasDiv = document.getElementById('vlibras');
                            vlibrasDiv.style.display = 'block';
                        }
                        $this.getSetupResponse(response)
                        resolve(response)
                    })
                  .catch((error) => {
                      console.log("Could Not resolve the setup 1");
                      $this.errorSetup();
                      reject(error);
                  });
            });
        },
        async getSetup() {
            try {
                const response = await axios.get(this.$url_api + "api/setup", {
                    headers: { 'language': this.$i18n.locale },
                    params: {
                        event: localStorage.getItem("eventmap"),
                        slug_page: extractSceneNameFromURL(window.location.href)
                    }
                });
                this.getSetupResponse(response);
            }
            catch (error) {
                console.log("Could Not resolve the setup 2");
                this.errorSetup();
            }
        },
        errorSetup() {
            let $this = this;
            localStorage.setItem('storageCleared', true)
            // Verifica se a limpeza já foi feita para evitar loop
            if (localStorage.getItem('storageCleared') && localStorage.getItem('user') == null) {
                window.$.suimodal({
                  title: '404 not found',
                  class: 'inverted',
                  closable: false,
                  content: 'World not detected, please contact the administrator',
                }).suimodal('show');
                // Limpa chaves específicas do localStorage
                localStorage.removeItem('per-stores');
                // localStorage.removeItem('eventmap');
                localStorage.removeItem('redirectPathEventLink');
                // Marca que a limpeza foi feita
                localStorage.removeItem('storageCleared');
            }
            else if(localStorage.getItem('storageCleared') && localStorage.getItem('user') != null) {
                localStorage.removeItem('storageCleared');
                const protocol = window.location.protocol;
                const host = window.location.hostname;
                const port = window.location.port ? `:${window.location.port}` : '';
                if (localStorage.getItem('loggedInWorld') === null) {
                    // Limpa chaves específicas do localStorage
                    localStorage.removeItem('per-stores');
                    localStorage.removeItem('eventmap');
                    localStorage.removeItem('redirectPathEventLink');
                    localStorage.removeItem('security_cookie');
                    localStorage.removeItem('user');
                    $this.logoutConfirmation(1);

                    // Marca que a limpeza foi feita
                    localStorage.removeItem('storageCleared');
                    // Redireciona para a home
                    const protocol = window.location.protocol;
                    const host = window.location.hostname;
                    const port = window.location.port ? `:${window.location.port}` : '';
                    window.location.href = `${protocol}//${host}${port}/#/`
                } else {
                    window.location.href = `${protocol}//${host}${port}/#/${localStorage.getItem('loggedInWorld')}`;
                }
            }
        },
        getSetupResponse(data) {
            this.update_event_id(data.data.event_id);

            this.show_captcha = (data && data.data && data.data.captcha && data.data.captcha == 1);
            this.tabname = data.data.Nome_Aba;
            if (localStorage.getItem("eventmap") != this.tabname) {
                localStorage.setItem("eventmap", this.tabname);
                if(this.$route.params.eventName && this.$route.params.eventName != this.tabname) {
                    let new_route = this.$route.fullPath.replace(this.$route.params.eventName, this.tabname);
                    this.$router.push(new_route);
                    return;
                }
                window.location.reload();
                return;
            }
            if (this.$route.name == "Signup" && this.$route.query._ === "84502530") {
                data.data.waiting_mode = false;
            }
            console.log('getSetupResponse => ', data.data);
            this.update_switch(data.data);

            this.setCookie("switch_Nome_Aba", this.switch.Nome_Aba);

            if (!this.html_template_loaded_) {
                this.html_template_loaded_ = true;
                this.createHTMLTemplate(data.data.html_template);
            }

            // caso o admin mude para nao habilitar o multilingua, força o usuário que estava em outra lingua para a lingua padrao
            if (!this.switch.multilanguages) {
                this.$i18n.locale = this.switch.default_language;
                localStorage.setItem('language', this.$i18n.locale);
                localStorage.removeItem('userSettedLanguage');
            } else {
                if (!localStorage.getItem('userSettedLanguage')) {
                    this.$i18n.locale = this.switch.default_language;
                    localStorage.setItem('language', this.$i18n.locale);
                    localStorage.setItem('userSettedLanguage', this.$i18n.locale);
                }
            }

            this.$store.dispatch("update_url_after_registration", data.data?.url_after_registration);

        },
        getSetupResponseWithPromise() {
            return new Promise((resolve) => {
                this.getSetupWithPromise().then(data => {
                    this.show_captcha = (data && data.data && data.data.captcha && data.data.captcha == 1);
                    this.tabname = data.data.Nome_Aba;
                    if (this.$route.name == "Signup" && this.$route.query._ === "84502530") {
                        data.data.waiting_mode = false;
                    }
                    this.getSetupResponse(data);
                    //   this.update_switch(data.data);
                    this.setCookie("switch_Nome_Aba", this.switch.Nome_Aba);
                    this.axiosSetup();
                    resolve();
                })
            })
        },
        webCamCapture(e) {
            e;
            this.webcam_canvas = document.querySelector("#webcam-photo-canvas");
            this.webcam_canvas.height = this.webcam_video.videoHeight;
            this.webcam_canvas.width = this.webcam_video.videoWidth;
            this.webcam_canvas_context = this.webcam_canvas.getContext("2d");
            this.webcam_canvas_context.drawImage(this.webcam_video, 0, 0);

            /*if (this.webcam_type == 'webcam_photo') {
                // document.getElementById("iframe").contentWindow.SelfieReady(this.webcam_canvas.toDataURL("image/png"));
                this.sendToUnity('SelfieReady', { data: this.webcam_canvas.toDataURL("image/png") });
            }*/
            document.getElementById("webcam-photo-capture").style.display = "none";
            document.getElementById("webcam-photo-discard").style.display = "block";
            document.getElementById("webcam-photo-save").style.display = "block";
        },
        webCamDiscard(e) {
            e;

            this.webcam_canvas_context.clearRect(
                0,
                0,
                this.webcam_canvas.width,
                this.webcam_canvas.height
            );
            document.getElementById("webcam-photo-discard").style.display = "none";
            document.getElementById("webcam-photo-save").style.display = "none";
            document.getElementById("webcam-photo-capture").style.display = "block";
        },
        webCamSave(e) {
            e;
            if (this.webcam_type == 'avatarsdk') {
                this.webcam_type = null;
                this.sendToUnity('SelfieReady', {data: this.webcam_canvas.toDataURL("image/png")});
                this.webCamExit(null);
                /*this.modal(
                    "status",
                    "Ok!",
                    this.$i18n.t('app.fotoCapturada'),
                    null,
                    null,
                    null,
                    "OK"
                );*/
            } /*else {
                this.wait(true);
                //var canvas = document.querySelector("#canvas");
                let root_ = this;
                let canvas_data = this.webcam_canvas.toDataURL("image/jpeg");
                var form_data = new FormData();
                form_data.append("image", canvas_data);

                let headers = {
                    "Content-Type": "application/json;charset=UTF-8",
                    Access: "application/json",
                    language: this.$i18n.locale, Authorization: `Bearer ${root_.token}`,
                };
                axios
                    .post(root_.$url_api + "api/user/sendWebCamPhoto", form_data, {
                        headers: headers,
                    })
                    .then((response) => root_.webCamSaveResponse(response))
                    .catch((error) => root_.webCamSaveError(error.response));
                /!*
                      this.webcam_canvas.toBlob(function (blob) {

                          var form_data = new FormData();
                          form_data.append('image', blob, 'webcam.jpg');

                          let headers = {
                              'Content-Type': 'application/json;charset=UTF-8',
                              'Access': 'application/json',
                              language: this.$i18n.locale, Authorization: `Bearer ${root_.token}`
                          };
                          axios.post(root_.$url_api + 'api/user/sendWebCamPhoto', form_data, { headers: headers }).then(response => root_.webCamSaveResponse(response)).catch(error => root_.webCamSaveError(error.response));

                      }, 'image/jpeg');
                      *!/
            }*/
        },
        webCamSaveResponse(obj) {
            obj;
            //console.log"webCamSaveResponse => ", obj);
            if (obj.data.success == "ok") {
                this.modal(
                    "status",
                    this.$i18n.t('app.info'),
                    this.$i18n.t('app.fotoSalva'),
                    null,
                    null,
                    null,
                    "OK"
                );
                this.webCamExit(null);
            }
            this.wait(false);
        },
        webCamSaveError(obj) {
            obj;
            //console.log"webCamSaveError => ", obj);
            this.wait(false);
        },
        //----- \webcam-photo\ -----//
        IOSInfo() {
            this.modal("status", this.$i18n.t('app.info'),
                this.$i18n.t('app.versaoIOS'),
                null, null, null, "OK");
        },
        video_pp(e) {
            e;
            //document.getElementById('iframe').contentWindow.document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('iframe').contentWindow.pp();
            //let data = { type:'pp' };
            //document.getElementById('iframe').contentWindow.document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('iframe').contentWindow.callingVuejsFunctionVideo(data);
            focusUnity(this.checkMobile);
            let frame = document
                .getElementById("iframe")
                .contentWindow.document.getElementsByTagName("iframe")[0];

            //frame.contentWindow.postMessage(data, 'https://sysnba.vrglass.com');
            frame.contentWindow.p();
        },
        video_fs(e) {
            e;
            //let data = { type:'fs' };
            //document.getElementById('iframe').contentWindow.document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('iframe').contentWindow.callingVuejsFunctionVideo(data);
            document.getElementById("iframe").click();
            let frame = document
                .getElementById("iframe")
                .contentWindow.document.getElementsByTagName("iframe")[0];
            frame.click();
            frame.contentWindow.f();
            //frame.contentWindow.postMessage(data, 'https://sysnba.vrglass.com');
        },
        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            let expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        deleteCookie(name) {
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },
        getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        wizardHandler() {
            if(this.logged || this.isGuest) {
                this.play_wizard = false;
                if (this.design && this.design.wizard)
                {
                    if ((this.design.wizard.play == 1) && (!this.design.wizard_check)) {
                        this.play_wizard = true;
                    } else if ((this.design.wizard.play == 2) && (!localStorage.getItem('wizard_check'))) {
                        localStorage.setItem('wizard_check', 1);
                        this.play_wizard = true;
                    }
                }
                if (this.play_wizard) {
                    if (this.design.wizard && this.design.wizard.play > 0 && this.design.wizard.title !== "" && this.design.wizard.url !== "") {
                        if ((this.checkMobile || this.checkIOS) && this.design.wizard.url_mobile)
                            this.modalI(this.design.wizard.title, this.design.wizard.url_mobile);
                        else
                            this.modalI(this.design.wizard.title, this.design.wizard.url);
                    }
                }
            }
            let cookie = this.getCookie(this.$route.name.toLowerCase() + "_wizard");
            if(cookie === ""){
                switch (this.$route.name) {
                    case "Account":
                    case "Profile":
                    case "TicketPurchased":
                    case "Friends":
                    case "FriendsInvited":
                    case "Ranking":
                    case "Ticket":
                    case "Partners":
                    case "Iframe":
                        if(!this.logged && !this.isGuest) {
                            break;
                        }
                        this.setCookie(
                            this.$route.name.toLowerCase() + "_wizard",
                            "wizard",
                            30
                        );
                        this.modal_Wizard = true;
                        break;
                    case "Support":
                        this.setCookie(
                            this.$route.name.toLowerCase() + "_wizard",
                            "wizard",
                            30
                        );
                        this.modal_Wizard = true;
                        break;
                    default:
                        this.modal_Wizard = false;
                        break;
                }
            }
        },
        /*
        wizardHandler() {
            //aparecer tutorial sempre q for para o mapa
            if (this.$route.name === "Map" && this.design.wizard && this.design.wizard.play !== 0 && this.design.wizard.title!=="" && this.design.wizard.url!=="" && this.design.wizard_check == null){
                if (this.design.wizard.play == 1){
                    this.setCookie(
                        this.$route.name.toLowerCase() + "_wizard",
                        "wizard",
                        30
                    );
                }
                if ( (this.checkMobile || this.checkIOS) && this.design.wizard.url_mobile)
                    this.modalI(this.design.wizard.title, this.design.wizard.url_mobile);
                else
                    this.modalI(this.design.wizard.title, this.design.wizard.url);
                return;
            }
            let cookie = this.getCookie(this.$route.name.toLowerCase() + "_wizard");
            if (cookie == "") {
                switch (this.$route.name) {
                    case "Map":
                        if (this.design.wizard && this.design.wizard.play == 1  && this.design.wizard.title!=="" && this.design.wizard.url!=="") {
                            if ( (this.checkMobile || this.checkIOS) && this.design.wizard.url_mobile)
                                this.modalI(this.design.wizard.title, this.design.wizard.url_mobile);
                            else
                                this.modalI(this.design.wizard.title, this.design.wizard.url);
                            this.setCookie(
                                this.$route.name.toLowerCase() + "_wizard",
                                "wizard",
                                30
                            );
                            break;
                        } else {
                            break;
                        }
                    case "Account":
                    case "Profile":
                    case "TicketPurchased":
                    case "VipRoom":
                    case "Support":
                    case "Friends":
                    case "FriendsInvited":
                    case "City":
                    case "Ranking":
                    case "Ticket":
                    case "Partners":
                    case "FanZone":
                    case "PlayZone":
                    case "FeelZone":
                    case "Store":
                    case "Museum":
                    case "LockerRoom":
                    case "Iframe":
                        this.modal_Wizard = true;
                        this.setCookie(
                            this.$route.name.toLowerCase() + "_wizard",
                            "wizard",
                            30
                        );
                        break;
                    default:
                        this.modal_Wizard = false;
                        break;
                }
            }
        },
        */
        openChatMobile() {
            this.openChatButton = !this.openChatButton;
        },
        tutorialHandler(e) {
            e;
            this.analytics("tutorial_home");
            this.tutorial = !this.tutorial;
        },
        aoVivoHandler() {
            if (this.EventimTickets) {
                for (let o = 0; o < this.EventimTickets.length; o++) {
                    if (this.EventimTickets[o].state == "Ao vivo") {
                        this.live = true;
                        return;
                    }
                }
            }
            this.live = false;
        },
//----- analytics -----//
        //event_name(string)
        //data(object)
        getImageTeam(team) {
            if (team != null)
                return (
                    this.$url +
                    "assets/images/" +
                    team
                        .trim()
                        .split(" ")[0]
                        .toUpperCase() +
                    ".png"
                );
        },
        getLastRoom() {
            let id_last_room = 0;
            if (localStorage.getItem("user")) {
                let user = JSON.parse(localStorage.getItem("user"));
                id_last_room = user.id_vip_actual;
            }
            return id_last_room;
        },
        inactivityConfirm(r) {
            if (r === 1) {
                this.count_inactivity = 0;
                this.count_inactivity_move = -1;
            }
        },
        checkInactivity() {
            this.count_inactivity++;
            ///console.log(this.count_inactivity);
            if (this.count_inactivity >= 3600) {
                switch (this.$route.name) {
                    case "Iframe":
                        this.count_inactivity = 0;
                        this.count_inactivity_move = 30;
                        this.modal(
                            "confirmation",
                            this.$i18n.t('app.ei'),
                            this.$i18n.t('app.voceAinda'),
                            this.inactivityConfirm
                        );
                        break;
                }
            }

            if (this.count_inactivity_move > 0) {
                this.count_inactivity_move--;
                try {
                    this.$refs["modal"].$refs["text-info"].innerHTML =
                        '<p style="text-align:center">' + this.$i18n.t('app.voceAinda') + '<br>' +
                        this.count_inactivity_move +
                        "</p>";
                } catch (e) {
                    //
                }
            } else if (this.count_inactivity_move == 0) {
                this.modal_ = false;
                this.count_inactivity = 0;
                this.count_inactivity_move = -1;
                switch (this.$route.name) {
                    case "Iframe":
                        this.link("Map");
                        break;
                }
            }
        },
        analytics(event_name, data) {
            event_name, data;
            //page, event, data;
            if (this.$gtag && this.securityContainer && this.securityContainer.admin_config.analytics) {
                this.$gtag.event(event_name);
            }
            if ((window._mtm) && (window._mtm.length > 0) && (this.securityContainer) && (this.securityContainer.admin_config.matomo)) {
                let newData = data;
                try {
                    if (data && typeof data === 'object' && !Array.isArray(data)) {
                        newData = JSON.stringify(data);
                    } else if (data && Array.isArray(data)) {
                        newData = JSON.stringify(data);
                    } else if (!data) {
                        newData = "CustomEvent";
                    }
                } catch (e) {
                    e;
                    newData = "CustomEvent";
                }
                let mtmData = {
                    'event': 'VTCustomEvent',
                    'mtm.category': event_name,
                    'mtm.name': event_name,
                    'mtm.localdata': newData
                };
                window._mtm.push(mtmData);
            }
            //console.log('analytics => ', event_name, data);
        },
        //----- \analytics\ -----//
        modal(type, title, txt, response, y, n, b, iframe) {
            switch (type) {
                case "success":
                case "error":
                case "confirmation":
                case "status":
                    if (!y && !n && !response) {
                        /* global $*/
                        $.suimodal({
                            title: title,
                            content: txt,
                            className: {
                                suimodal: 'ui suimodal mini translucent background-transparent'
                            },
                            autoShow: true,
                            closable: true,
                            allowMultiple: true,
                            actions: [
                                {
                                    text: b,
                                    className: 'ui button positive',
                                    on: function () {
                                        return true;
                                    }
                                },
                            ],
                        });
                        return;
                    }
            }
            this.modal_type = type;
            this.modal_title = title;
            this.modal_text = txt;
            this.modal_response = response;
            this.modal_yes = y;
            this.modal_no = n;
            this.modal_btn = b;
            this.modal_iframe_url = iframe;
            this.modal_ = true;
        },
        checkIsImage(s) {
            return (s.substring(s.length - 3) == 'png' || s.substring(s.length - 3) == 'jpg' || s.substring(s.length - 3) == 'gif' || s.substring(s.length - 4) == 'jpeg');
        },
        modalI(title, iframe, response, type = "") {
            this.modal(
                (this.checkIsImage(iframe) ? "image" : "iframe") + (type ? "_" + type : ""),
                title,
                null,
                response,
                null,
                null,
                null,
                iframe);
        },
        modalIframe(type) {
            this.modal_iframe_type = type;
            this.modal_iframe = true;
        },
        finishForm(ev) {
            if (ev && ev.data && ev.data.message) {
                if (ev.data.message == 'close_form') {
                    setTimeout(() => {
                        this.modal_iframe = false
                        this.modal_ = false
                        document.getElementById('iframe').contentWindow.postMessage({
                            message_type: "external",
                            data: "krpano_modal",
                            params: {}
                        }, '*')
                    }, 1500)
                }
            }
        },
        sessionAccess(type) {
            this.session_access_type = type;
            this.chat = false
            this.switch_avatar_new = false;
            this.ambient_transition = false;
            this.session_access = true;
        },
        bodyOverflow(change = true) {
            let element = document.getElementsByTagName("body")[0];
            if (change) {
                element.style.overflow = "hidden";
                return;
            }
            element.style.overflow = "auto";
        },
        checkAge() {
            if (this.age >= 18) return true;
            else return false;
        },
        checkLive() {
            let count_live = 0;
            for (let o = 0; o < this.EventimTickets.length; o++) {
                if (this.EventimTickets[o].state == "Ao vivo") {
                    count_live++;
                }
            }
            if (count_live > 0) return true;
            else return false;
        },
        ...mapActions([
            "update_logged", "update_id", "update_token", "update_email", "update_profile",
            "update_started", "update_url_actual", "update_age_verified", "update_email_verified",
            "update_coin", "update_point", "update_totalbadges", "update_dat", "update_notifications", "update_notifications_count", "update_switch",
            "update_user_tickets_del", "update_eventim_tickets", "update_sessions", "update_guest",
            "update_change_password_social", "update_webview", "update_groups", "update_id_form_actual",
            "update_current_session", "update_design", "update_stores", "update_notification_datas", "update_checkers", "update_quest",
            "update_notifications_private_message", "update_notifications_del", "update_user_picture", "update_reward_slug", "update_use_avatar", "update_avatar_metaverse",
            "update_notifications_notify_points", "update_header_buttons", "update_type_validation", "update_login_social",
            "reset_unity_hash_log", "update_time_unity_hash_log",
            "update_radio", "update_notifications_pendent_ids", "update_event_id", "update_mini_game_header"
        ]),
        wait(b, unity_ = null) {
            if (unity_ != null) {
                // if (unity_ === true)
                this.loading = b;
                this.loading_unity = b;
            } else {
                if (!this.loading_unity) {
                    this.loading = b;
                }
            }

            //console.log('WAIT => ', b, this.loading_unity);
        },
        menuMobileHandler() {
            console.log(this.menu_active);
            this.menu_active = !this.menu_active;
            console.log(this.menu_active);
            document.getElementById("menu").style.display = this.menu_active
                ? "flex"
                : "none";
        },
        varifyLoggedError(error) {
            if (error.status == 401 || error.message == "Unauthenticated.") {
                this.logoutEnd();
            }
        },
        //----- saveUser -----//
        saveUser(dat_) {
            localStorage.setItem("user", JSON.stringify(dat_));
        },
        updateUser(attribute, v) {
            let data_temp = localStorage.getItem("user");
            data_temp = JSON.parse(data_temp);
            data_temp[attribute] = v;
            localStorage.setItem("user", JSON.stringify(data_temp));
        },
        //----- \saveUser\ -----//
        //----- logout -----//
        logout() {
            if (this.isGuest) {
                this.modal("confirmation", this.$i18n.t('app.atencao'), this.$i18n.t('guest.fazerLogout'), this.logoutConfirmation);
            } else {
                this.modal("confirmation", this.$i18n.t('app.atencao'), this.$i18n.t('app.desejaRealmenteSair'), this.logoutConfirmation);
            }
            this.$store.dispatch("signup/reset");
            let eventName = new Event("closeMenu");
            document.dispatchEvent(eventName);
        },
        logoutConfirmation(r) {
            if (r === 1) {
                this.analytics("logout_btn");
                this.wait(true);
                let form_data = new FormData();
                // remove navigation
                let user_id = JSON.parse(localStorage.getItem("user")).id;
                axios
                    .post(this.$url_api + "api/trackUser", { userId: user_id }, {headers: this.headers})
                    .catch((error) => this.logoutError(error.response));

                axios
                    .post(this.$url_api + "api/logout", form_data, {headers: this.headers})
                    .then((response) => {
                        this.logoutResponse(response)
                    })
                    .catch((error) => this.logoutError(error.response));
            }
        },
        logoutResponse(obj) {
            obj;
            this.logoutEnd();
            this.wait(false);
        },
        logoutError(obj) {
            this.varifyLoggedError(obj.data);
            this.wait(false);
        },
        logoutEnd() {
            /*
                  window.FB.logout(function(response) {
                      console.log(response);
                      // user is now logged out
                  });
                  */


            //localStorage.removeItem("eventmap");
            //localStorage.removeItem("eventSessionLink");
            if (typeof window._paq == "object") {
                window._paq.push(["resetUserId"]);
            }
            localStorage.removeItem("user");
            this.deleteCookie("guest_last_join");
            localStorage.removeItem("design_eventlogo");
            if (this.logged) {
                this.update_logged(false);
            }
            emitter.emit("LmsHeader::logout");
            this.update_started(false);
            if (this.isGuest) {
                this.update_guest(false)
                localStorage.removeItem("guest")
            }
            this.update_token(null);
            // this.link("Home");

            localStorage.removeItem('wizard_check');
            localStorage.removeItem('redirectPath');
            localStorage.removeItem('userSettedLanguage');
            this.modal_private_messages = false;

            this.$store.dispatch('LMS/disableLms')
                .then(() => {
                    document.body.classList.remove('lms');
                    document.body.classList.remove('transparent');
                    let header = document.getElementsByClassName('menu logged')[0];
                    header.style.setProperty('background-color', 'var(--headers_color_logged)', 'important');
                })
            this.getSetup();

            if ((localStorage.getItem("eventmap")) && (localStorage.getItem("eventmap") != '')) {
                window.location.href = this.$url + '#/' + localStorage.getItem("eventmap");
            } else {
                console.log('caindo no redirect do eventmap :: 2')
                this.$router
                    .push({path: '/'})
                    .then(() => {
                        this.$router.go();
                    });
            }

            if (this.switch.without_landing_page == 'without_landing_page') {
                // location.reload(true)
                this.createGuest()
            }
        },
        changeGuest() {
            this.analytics("logout_btn");
            this.wait(true);
            let form_data = new FormData();
            axios
                .post(this.$url_api + "api/logout", form_data, {headers: this.headers})
                .catch((error) => this.logoutError(error.response));

        },
        createGuestVerifyCaptcha() {
            if (this.switch.captcha) {
                this.modal("confirmationCaptcha", this.$i18n.t('guest.titulo-captcha'),
                    this.$i18n.t('guest.desc-captcha'), this.createGuest, this.$i18n.t('guest.confirmar'), this.$i18n.t('guest.cancelar'));
            } else {
                this.createGuest();
            }
        },
        createGuest(status, captcha) {
            if (status == 0) {
                return;
            }
            this.wait(true)
            let form_data = new FormData();
            form_data.append('g-recaptcha-response', captcha)
            axios
                .post(this.$url_api + "api/loginAsGuest", form_data, {headers: this.headers})
                .then((response) => this.createGuestResponse(response))
                .catch((error) => this.createGuestError(error.response));
        },
        async createGuestResponse(response) {
            this.login_date = new Date();
            this.update_id(response.data.id);
            this.update_logged(false);
            this.update_guest(true);
            localStorage.setItem('guest', 'on')
            this.update_token(response.data.token);
            this.update_email_verified(true);
            await this.getUser();
            console.log('chegando no guest')
            this.link('Map');
            console.log('redirecionando guest')
            // location.reload(true);
        },
        createGuestError(response) {
            if (
                response.data.message == "erro_desconhecido" ||
                response.data.status == "error"
            ) {
                let str = "";
                for (let o in response.data.errors) {
                    str += response.data.errors[o] + "\n";
                }
                this.modal("error", response.data.message, str, null, null, null, "OK");
            }
            this.wait(false);
        },
        //----- \logout\ -----//
        //----- delete -----//
        deleteAccount() {
            if (this.RecurrentPayment) {
                alert(this.$i18n.t('app.recurrentPayment'));
            } else {
                this.modal("confirmationCaptcha", this.$i18n.t('app.atencao'),
                    this.$i18n.t('app.aoConfirmar'), this.deleteAccountConfirmation);
            }

        },
        deleteAccountConfirmation(r, gcaptcha) {
            if (r === 1) {
                this.wait(true);
                let data = {"g-recaptcha-response": gcaptcha};
                axios.delete(this.$url_api + "api/user", {
                    headers: this.headers,
                    data: data,
                }).then((response) => this.deleteAccountResponse(response))
                    .catch((error) => this.deleteAccountError(error.response));
            }
        },
        deleteAccountResponse(obj) {
            obj;
            this.update_checkers(0);
            this.$store.dispatch("signup/reset")
            this.logoutEnd();
            this.wait(false);
        },
        deleteAccountError(obj) {
            if (obj.data.message == "winner_not_delete") {
                this.modal("error", this.$i18n.t('app.erro'), obj.data.error, null, null, null, "OK");
            } else if (obj.data.message == "The given data was invalid.") {
                this.modal("error", this.$i18n.t('app.erro'), this.$i18n.t('app.vocePrecisa'),
                    this.deleteAccount, null, null, "OK");
            }
            this.varifyLoggedError(obj.data);
            this.wait(false);
        },
        openSelfieModal() {
            this.analytics("selfie_btn");
            this.$refs["selfieControl"].open();
        },
        //----- \delete\ -----//
        updatePopupNotifications() {

            this.notification_list = this.NotificationsList;
            console.log('### updatePopupNotifications 111 => ', this.notification_list);
            this.popup_notifications = this.notification_list.filter(_ => _.popup && _.show_popup);
        },
        refreshNotificationPrivateMessages(friend_id) {
            let index = Object.keys(this.notification_list).find(x => this.notification_list[x].friend_id === friend_id && this.notification_list[x].type == "private_message");
            this.update_notifications_del(index);
        },

        /* LMS */
        LMSHandler(is_lms, origin__ = '', event__ = '', session__ = '') {
            console.log('is_lms => ', is_lms);
            if (origin__ == 'map') {
                console.log('DIRECIONAMENTO MAP', this.$route.name);
                // if (is_lms && this.$route.name != 'LmsHome') {
                if (is_lms) {
                    this.is_lms_session = false;
                    this.$router.push({path: `/lms/${is_lms.slug}`});
                }
            } else if ((origin__ == 'app') && (event__ == '') && (session__ == '')) {

                if (is_lms && (this.$route.name == 'Home')) {
                    console.log('ENTROU NO IF 1 --------------------');
                    this.is_lms_session = false;
                    this.$router.push({path: `/lms/${is_lms.slug}`});
                } else if (is_lms && this.$route.name == 'EventMap') {
                    console.log('ENTROU NO IF 2 --------------------');
                    this.is_lms_session = false;
                    this.$router.push({path: `/lms/${is_lms.slug}`});
                } else if (is_lms && (this.$route.name != 'LmsHome' || this.is_lms_session)) {

                    let index = this.sessions.map(e => e.name).indexOf(this.lms_session_name);
                    let course_id = this.sessions[index].course_id;
                    let index2 = Object.keys(is_lms.courses).find(
                        x => is_lms.courses[x].id === course_id
                    );
                    let slug = is_lms.courses[index2].slug;
                    // console.log('sssssssssssssssssssssssssss', index2, slug);

                    this.$router.push({path: `/lms/${is_lms.slug}/${slug}`});
                } else if (is_lms && (this.$route.name != 'LmsHome' && !this.is_lms_session)) {
                    console.log('ENTROU NO IF 4 --------------------');
                    this.is_lms_session = false;
                    this.$router.push({path: `/lms/${is_lms.slug}`});
                }
            } else if ((origin__ == 'app') && (event__ != '')) {
                //direcionar aqui evento
                if (is_lms && this.$route.name != 'LmsHome') {
                    this.is_lms_session = false;
                    this.$router.push({path: `/lms/${is_lms.slug}`});
                }
            } else if ((origin__ == 'app') && (session__ != '')) {
                this.is_lms_session = true;
                this.lms_session_name = session__;
                if (is_lms && this.$route.name != 'LmsHome')
                    // this.$router.push({name: 'LMSCourses'});
                    this.$router.push({path: `/lms/${is_lms.slug}/courseSlug`}); // <-- rever isso
            }
        },
        //----- user-info -----//
        getUser() {
            if (typeof window._paq == "object") {
                window._paq.push(["setUserId", this.email]);
            }
            //console.log'eventmap => ', localStorage.getItem("eventmap"));
            this.wait(true);
            return axios
                .get(this.$url_api + "api/welcome", {
                    headers: this.headers,
                    params: {eventmap: localStorage.getItem("eventmap"), current_page_name: this.$route.name, current_page_path: this.$route.path}
                })
                .then((response) => this.getUserResponse(response))
                .catch((error) => {
                    this.getUserError(error.response)
                });
        },
        getUserResponse(obj) {
            console.log('### getUserResponse => ', obj);
            /* LMS */
            this.$store.dispatch("signup/reset")
            let dat = new Date(obj.data.dat);
            let id_last_room_ = this.getLastRoom();
            if (id_last_room_ > 0) {
                this.chat_room = "BarVip" + id_last_room_;
                this.chat_owner =
                    JSON.parse(localStorage.getItem("user")).owner_vip_actual +
                    " " +
                    id_last_room_;
                obj.data.id_vip_actual = id_last_room_;
                obj.data.owner_vip_actual = JSON.parse(
                    localStorage.getItem("user")
                ).owner_vip_actual;
            }
            this.eventlogo = obj.data.logo;
            this.logoHandler();
            obj.data.dat = dat;
            obj.data.token = this.token;
            obj.data.email_verified = this.email_verified;
            obj.data.id = this.id;
            if (typeof window._paq == "object") {
                window._paq.push(["setUserId", obj.data.email]);
            }
            this.notifications_count = obj.data.notification_count;
            this.RecurrentPayment = obj.data.RecurrentPayment;
            this.update_notifications(obj.data.NotificationsList);
            this.update_notifications_count(obj.data.notification_count);
            this.update_notifications_pendent_ids(obj.data.notification_pendent_ids);
            this.updatePopupNotifications()
            this.update_age_verified(obj.data.birth_day == false ? false : true);
            this.update_profile(obj.data);
            this.update_use_avatar(obj.data.use_avatar);
            this.update_avatar_metaverse(obj.data.avatar_metaverse);
            this.update_user_picture(obj.data.custom_profile_photo)
            if (obj.data.is_guest) {
                this.update_guest(true);
                this.update_logged(false);
                this.setCookie("guest_last_join", obj.data.last_acess);
            } else {
                this.update_guest(false);
                this.update_logged(true);
            }
            this.setCookie("switch_Nome_Aba", this.switch.Nome_Aba)
            if (
                obj.data.birth_day == false &&
                this.$route.name !== "EmailConfirmation"
            ) {
                this.link("Age");
            }
            this.saveUser(obj.data);

            // If the user is coming from the 'signup' page and the 'url after registration' is set,
            // redirect them after login IF user is verified
            if (this.userOrigin === 'signup' && this?.url_after_registration?.length) {
                if (this.email_verified) {
                    window.top.location.href = this.url_after_registration;
                }
            }

            try {
                this.$refs["mobile"].refreshMenu();
            } catch (e) {
                e;
            }
            this.update_dat(dat);
            this.update_groups(obj.data.groups);
            // this.groupHandler();
            this.update_started(true);
            //temp
            //this.checkVideoAvatar(this.$route.name);

            this.radio = obj.data.radio;
            this.update_radio(this.radio);

            this.switch.multilanguages = obj.data.Multilanguage;
            this.switch.active_languages = obj.data.switch.active_languages;
            this.switch.default_language = obj.data.switch.default_language;

            // caso o admin mude para nao habilitar o multilingua, força o usuário que estava em outra lingua para a lingua padrao
            if (!this.switch.multilanguages) {
                this.$i18n.locale = this.switch.default_language;
                localStorage.setItem('language', this.$i18n.locale);
                localStorage.removeItem('userSettedLanguage');
            } else {
                if (!localStorage.getItem('userSettedLanguage')) {
                    console.log('Trocando para a lingua padrao');
                    this.$i18n.locale = this.switch.default_language;
                    localStorage.setItem('language', this.$i18n.locale);
                    localStorage.setItem('userSettedLanguage', this.$i18n.locale);
                }
            }

            this.update_switch(obj.data.switch)

            // this.LMSHandler(obj.data.lms??false, 'app', '', '');

            this.wizardHandler();
            this.wait(false);

            if (this.openPackageAfterRegister) {
                this.openPackage();
                this.openPackageAfterRegister = false;
            }

            // Custom CSS
            const styleElement = document.createElement("style");
            styleElement.textContent = obj.data.custom_css;
            document.head.appendChild(styleElement);
            this.axiosSetup();
        },
        getUserError(obj) {
            if (obj) {
                this.varifyLoggedError(obj.data);
                this.wait(false);
                // this.logout();
            }
        },
        //----- \user-info\ -----//
        stopSound() {
            try {
                //console.log('playinf => ', document.getElementById('player-iframe').contentWindow.audio_active);
                if (document.getElementById("player-iframe").contentWindow.audio_active)
                    document.getElementById("player-iframe").contentWindow.playPause();
            } catch (e) {
                e;
            }
        },
        //----- link -----//
        link(url) {
            if (this.$route.name != url) {
                this.$router.push({name: url});
            }
        },
        //----- link -----//

        //----- sendToUnity -----//
        sendToUnity(event_type, unity_data) {
            this.analytics('sendToUnity', event_type)
            try {
                document.getElementById("iframe").contentWindow.SendMessageToUnity(event_type, unity_data);
            } catch ({error}) {
                try {
                    document.getElementById("iframe").contentWindow.postMessage({
                        type: "SendMessageToUnity",
                        event_type,
                        unity_data
                    }, "*");
                } catch ({e2}) {
                    console.log('sendToUnity error');
                }
            }
        },
        //----- \sendToUnity\ -----//

        xor_this(str, key) {
            let result = '';
            for (let i = 0; i < str.length; i++) {
                const charCode = str.charCodeAt(i) ^ key.charCodeAt(i % key.length);
                result += String.fromCharCode(charCode);
            }
            return result;
        },

        async openExternalGame(hash) {
            this.wait(true);
            if (hash.game_rule && hash.game_rule.endpoint) {
                // crediting
                let payload = {
                    mt: this.token,
                    score: 0,
                    winner: 0
                };
                payload = btoa(this.xor_this(JSON.stringify(payload), 'fd6c3063050bf91635a40f54836774f7296704f206db25a5cf363de34e7496cc32a3a6dff1189ee86421eaf868faa09b504cfe04ae348ea5fae8f1fca538f7180ed7ff745ba72029274933a8105e3417ebb60523fce3432cdbcccb620693f4ff0f3facc92281145145ef523e842fa4c624d600267bc9ac662d6cf55ead17685'));
                await axios.post(this.$url_api + "api/credit/play_game/" + hash.game_rule.endpoint, {
                    ambient: payload,
                    give_coins_points: hash.give_coins_points
                }, {headers: this.headers})
                    .then(_ => _).catch(_ => _);
            } else
                console.log('invalid credit external game point');

            console.log('opening the external game...');

            // sugestão de melhoria aqui, tornar opcional o tipo de abertura do jogo (modal ou nova aba ou mesma aba)
            //window.open(hash.hash);
            this.modal('iframe', hash.label, '', null, '', '', '', hash.hash);

            this.wait(false);
        },

        //----- external -----//
        externalEventHandler(e) {
            console.log('externalEventHandler HHHHHHHHHHHHHHHHH => ', e.detail.data);
            let root_ = this;
            if (e.detail.data.type == "mural") {
                this.$refs["muralPosts"].open();
            }
            if (e.detail.data.type == "coinsBuy") {
                if (this.switch.finance_profile_menu) {
                    if (e.detail.data.key) {
                        this.wait(true);
                        this.package_id = Number(e.detail.data.key);
                        let form_data = new FormData();
                        axios
                            .post(this.$url_api + 'api/package/load', form_data, {headers: this.headers})
                            .then(response => this.loadCoinsBuyResponse(response))
                            .catch(error => this.loadCoinsBuyError(error.response));
                    } else {
                        this.modal_coins_buy = (this.modal_package) ? false : true;
                    }
                }
            }
            if (e.detail.data.type == "tickets") {
                if (e.detail.data.key) {
                    this.wait(true);
                    this.ticket_gate_id = Number(e.detail.data.key);
                    axios.get(this.$url_api + 'api/GetTickets', {headers: this.headers}).then(response => this.loadCheckUserTicketsResponse(response)).catch(error => this.loadCheckUserTicketsError(error.response));
                } else {
                    this.modal_ticket_gate = true;
                }
            }
            //----- \MISSOES\ -----//
            /*
            // MIGRADO PARA unity_message - PODEMOS APAGAR
            if(e.detail.data.type == "quests") {
                console.log('chegou no quest event handler');
                this.wait(true);
                // this.modal_quest = false;
                let params  = {
                    event: e.detail.data.event?Number(e.detail.data.event):null,
                    session: e.detail.data.session?Number(e.detail.data.session):null
                };
                let headers = {
                    "Content-Type": "application/json;charset=UTF-8",
                    Access: "application/json",
                    language: this.$i18n.locale, Authorization: `Bearer ${this.token}`,
                };
                // console.log(params);
                axios
                    .get(this.$url_api + 'api/welcome-unity-quest', {
                        headers:headers,
                        params:params
                    })
                    .then(response => this.loadUnityQuestResponse(response))
                    .catch(error => this.loadUnityQuestError(error.response));
            }
            if(e.detail.data.type == "quest_check_in") {
                // window.top.callingVuejsFunction({type: "quest_check_in", quest: 5, step: 8});
                if( this.quest ) {
                    this.quest_id       = e.detail.data.quest;
                    this.quest_step_id  = e.detail.data.step;
                    this.modal_quest    = true;
                }
            }
            // END MIGRADO PARA unity_message
            */
            if (e.detail.data.type == "sku") {
                this.$refs['header_main'].$refs[this.store_active].setProduct(e.detail.data.url);
            }

            //----- KRPano -----//
            if (e.detail.data.type == "krpano") {
                /*
                  let ar_url = String(e.detail.data.url).split("/");
                  this.$router.push({path: String('/e/' + ar_url[ar_url.length - 1])});
                 */
                let session = e.detail.data.session;
                let event = e.detail.data.event;
                if (!session || !event) {
                    let ar_url = String(e.detail.data.url).split("/");
                    session = ar_url[ar_url.length - 1];
                    event = localStorage.getItem("eventmap");
                }
                let lms_found = false;
                this.sessions.find(obj => {
                    if (session && String(obj.name) === String(session)) {
                        if (obj.lms && obj.lms.slug) {
                            lms_found = String(obj.lms.slug);
                        }
                    }
                });
                if (lms_found) {
                    this.$router.push({path: `/lms/${lms_found}`});
                } else {
                    this.$router.push({name: 'Iframe', params: {sessionName: session, eventName: event}});
                }
                return;
            } else if (e.detail.data.type == "krpano_communication") {
                
                let root = this;
                //setTimeout(() => {
                let krpano_communication = JSON.stringify({
                    design: root.design,
                    user_badges: root.UserBadges,
                    user_tickets: root.UserTickets,
                    lock_favicon: root.design.lock_favicon,
                    avatar_icon: root.design.avatar_icon,
                    avatar_map: root.avatar_map,
                    avatar_first_session: root.avatar_first_session,
                    last_local: root.last_local,
                    sessions: root.sessions,
                    all_badges: root.AllBadges,
                    event_map_user_progress: root.event_map_user_progress,
                    map_user_progress: root.maps?.map_user_progress
                });
                
                // let krpano_communication = JSON.stringify( { sessions: this.sessions, user_badges: this.UserBadges, user_tickets: this.UserTickets, lock_favicon: this.design.lock_favicon } );
                // let krpano_communication =    JSON.stringify( { design: this.design, user_badges: this.UserBadges, user_tickets: this.UserTickets, lock_favicon: this.design.lock_favicon, avatar_icon: this.design.avatar_icon, avatar_map: this.avatar_map, avatar_first_session: this.avatar_first_session, last_local: this.last_local, sessions: this.sessions } );
                try {
                    document.getElementById('iframe').contentWindow.calling_vuejs_function("front_communication", krpano_communication);
                } catch (e) {
                    document.getElementById('iframe').contentWindow.postMessage({
                        message_type: "external",
                        data: "front_communication",
                        params: krpano_communication
                    }, "*");
                }
            //}, 1000);


            } else if (e.detail.data.type == "krpano_analytics") {
                //e.detail.data.scene
            } else if (e.detail.data.type == "session_access") {
                this.sessionAccess(e.detail.data.subtype);
            }

            //----- \KRPano\ -----//
            //console.log'external => ', e.detail.data);
            //console.log('UNITY MESSAGE => ', e.detail.data);
            switch (e.detail.data.type) {
                case "reward":
                    this.getRewardsFake('ddas');
                    break;
                case "admin":
                    switch (e.detail.data.subtype) {
                        case "close_modal":
                            this.modal_iframe = false;
                            break;
                    }
                    break;
                case "webcam_photo":
                    this.webCamStart(e.detail.data.model);
                    break;
                //----- Denúncia chat -----//
                case "user_chat_complaint":
                    this.complaintOpenHandler(e.detail.data.user_id);
                    break;
                case "user_chat_add_friend":
                    this.requestAddFriend(e.detail.data.user_id);
                    break;
                //----- \Denúncia chat\ -----//

                //----- Unity -----//
                case "unity_message":
                    switch (e.detail.data.subtype) {
                        case "chat":
                            this.openChatMobile();
                            break;

                        case 'minigames':
                            this.$refs['main'].Appcheck();
                            this.update_mini_game_header(true);
                            break;
                        case 'take_snapshot':
                            this.selfie(`data:image/png;base64,${e.detail.data.value}`)
                            break;
                        case "quests":
                            // console.log('chegou no quest event handler');
                            this.wait(true);
                            // this.modal_quest = false;
                            var params = {
                                event: this.event_id,
                                session: this.current_session.id
                            };
                            // console.log(params);
                            axios
                                .get(this.$url_api + 'api/welcome-unity-quest', {
                                    headers: this.headers,
                                    params: params
                                })
                                .then(response => this.loadUnityQuestResponse(response))
                                .catch(error => this.loadUnityQuestError(error.response));
                            break;
                        case "quest_check_in":
                            /*
                            if( this.quest ) {
                                this.quest_id       = e.detail.data.quest;
                                this.quest_step_id  = e.detail.data.step;
                                this.modal_quest    = true;
                            }
                            */
                            if (this.quest) {
                                var quest_ar = JSON.parse(e.detail.data.value);
                                this.quest_id = quest_ar.quest;
                                this.quest_step_id = quest_ar.step;
                                this.modal_quest = true;
                            }
                            break;
                        case "agoraio":
                            if (this.switch.unity_conf) {
                                this.decryptUnity(
                                    this.keyUnityConf,
                                    this.switch.unity_conf.i,
                                    this.switch.unity_conf.c
                                ).then(objDec => {
                                        this.sendToUnity('SetAgoraId', objDec);
                                    }
                                );
                            }
                            break;
                        case "room":
                            if (this.group_id) {
                                const group = this.groups.find(_ => _.id == this.group_id);
                                this.addTranslucentModal({
                                    size: 'sm',
                                    title: this.$t('translucent_modal.group_room.title'),
                                    content: this.$t('translucent_modal.group_room.content', {group_name: group ? group.name : ''}),
                                    confirm_text: this.$t('translucent_modal.group_room.confirm'),
                                    can_cancel: false,
                                    close_background: false,
                                });
                            }
                            var chat_name_final = ((e.detail.data.value !== undefined) ? e.detail.data.value : e.detail.data.room);
                            var chat_max_temp_;
                            if (!this.current_session.chat_max && !this.switch.chat_max_unity) {
                                chat_max_temp_ = 100;
                            } else
                                chat_max_temp_ = this.current_session.chat_max ? this.current_session.chat_max : this.switch.chat_max_unity;

                            if (this.current_session.video) {
                                if (this.current_session.video.indexOf('youtube') !== -1 && this.current_session.video.indexOf('embed') !== -1) {
                                    this.iframe_temp = true;
                                    this.iframe_temp_url = this.current_session.video;
                                    this.updateIframeTempCss()
                                }
                            }
                            this.chat_audio = this.current_session.video_chat ? true : false;
                            this.chat_participants = this.current_session.chat_participants ? true : false;
                            if (this.current_session.live_chat) {
                                this.chat_max = 0;
                                this.chat_room = this.current_session.live_chat;
                            } else {
                                this.chat_max = chat_max_temp_;
                                this.chat_room = chat_name_final;
                            }
                            this.chat_interactive = (this.switch.chat && this.current_session.chat);
                            this.chat = false;
                            this.switch_avatar_new = this.current_session.switch_avatar;
                            this.ambient_transition = this.switch.quiz && this.current_session.quiz;
                            this.show_quiz = this.switch.quiz && this.current_session.quiz;
                            this.allRelatedFriends();
                            this.createPlayer();
                            this.$nextTick(() => {
                                this.chat = true;
                            });
                            if (this.checkIOS || this.checkMobile) {
                                this.openChatMobile()
                            }
                            break;
                        case "navigateto":
                            if (e.detail.data.value == 'map') {
                                this.$router.push({name: 'Map'});
                            } else if (e.detail.data.value == 'map' && this.$route.name == 'Map') {
                                window.location.reload();
                            } else {
                                this.$router.push({
                                    name: 'Iframe',
                                    params: {
                                        sessionName: e.detail.data.value,
                                        eventName: localStorage.getItem("eventmap")
                                    }
                                });
                            }

                            break;
                        case 'lightbox':
                            if (e.detail.data.value == 'webcam_photo') {
                                //value = selfie, mural
                                this.webCamStart("avatarsdk");
                            } else if (e.detail.data.value == 'mural') {
                                if (e.detail.data.id ?? false) {
                                    this.$refs["muralPosts"].open(e.detail.data.id);
                                } else {
                                    this.$refs["muralPosts"].open();
                                }
                            }
                            break;
                        case "open_loader":
                            this.wait(true, true);
                            break;
                        case "close_loader":
                            this.wait(false, true);
                            break;
                        case  "share_mosaico":
                            var urlShare = e.detail.data.url;
                            var ios = this.checkIOS;
                            var android = this.checkMobile;
                            if (ios || android) {
                                try {
                                    window.navigator.share({
                                        title: 'BaconMix',
                                        text: 'Conheça esse áudio incrível que eu criei no Metaverso Seara Gourmet.',
                                        url: urlShare
                                    });
                                } catch (error) {
                                    this.$refs["shareComponent"].open(urlShare);
                                }
                            } else {
                                this.$refs["shareComponent"].open(urlShare);
                            }
                            break;
                        case "product_sku":
                            this.$refs['header_main'].$refs[this.store_active].setProduct(e.detail.data.product_sku);
                            break;
                        case "initialized":
                            this.update_mini_game_header(false);
                            localStorage.setItem("minigame_id", null);
                            localStorage.setItem("session_name", null);
                            localStorage.setItem("background_url", null);

                            if (e.detail.data.value == "474f5d57e4a6afd93b716b7b5a4b6f17") {
                                let json_base_data =
                                    {
                                        session_id: this.current_session.id,
                                        token: this.token,
                                        language: this.$i18n.locale,
                                        event_id: this.event_id,
                                        api_url: (this.$url_api + 'api/'),
                                        is_mobile: this.isMobile,
                                        current_session: this.current_session,
                                        user: {
                                            id: this.id,
                                            email: this.email,
                                            name: this.name,
                                            nickname: this.nickname,
                                            age: this.age,
                                            TotalPoints: this.TotalPoints,
                                            TotalCoins: this.TotalCoins,
                                            TotalBadges: this.TotalBadges
                                        },
                                        sessions: this.sessions,
                                        max_chat: this.chat_max,
                                        chat: (this.switch.chat && this.current_session.chat),
                                        audio: this.current_session.audio,
                                        quality_level: this.current_session.quality_level,
                                        transcription_active: this.current_session.transcription,
                                    };
                                this.sendToUnity('SetBaseData', json_base_data);
                            }
                            break;
                        case "initialized_minigame":
                            this.update_mini_game_header(true);
                            this.$refs['main'].Appcheck();
                            emitter.emit("Map::Appcheck");
                            localStorage.setItem("minigame_id", this.current_session.minigame_id);
                            localStorage.setItem("session_name", this.current_session.name);
                            localStorage.setItem("background_url", this.current_session.template_options.fixed_background);
                            this.sendToUnity('SetBaseData', {
                                user_id: this.id,
                                session_id: this.current_session.id,
                                minigame_id: this.current_session.minigame_id,
                                api_url: (this.$url_api + 'api/'),
                                token: this.token
                            });

                            this.$store.dispatch("Minigames/getMinigamesPoints", {
                                "user_id": this.id,
                                "minigame_id": this.current_session.minigame_id
                            });
                            break;
                        case "ranking_screen":
                            emitter.emit('Minigames::ranking::modal', true);
                            break;
                        case "gt":
                            if (e.detail.data.key == "474f5d57e4a6afd93b716b7b5a4b6f17") {
                                let dMyT = {
                                    token: this.token,
                                    language: this.$i18n.locale,
                                    event_id: this.event_id,
                                    session_id: this.current_session.id
                                }
                                try {
                                    document.getElementById("iframe").contentWindow.myT(dMyT.token, dMyT.language, dMyT.event_id, dMyT.session_id);
                                } catch (e) {
                                    document.getElementById("iframe").contentWindow.postMessage({
                                        ...dMyT,
                                        type: "myT"
                                    }, "*");
                                }

                            } else {
                                try {
                                    document.getElementById("iframe").contentWindow.myT("Chave não reconhecida.");
                                } catch (e) {
                                    document.getElementById("iframe").contentWindow.postMessage({
                                        type: "myT",
                                        token: "Chave não reconhecida."
                                    }, "*");
                                }
                            }
                            break;
                        case "open2buy":
                        case "video":

                            if (this.current_session.hashs) {
                                for (let o = 0; o < this.current_session.hashs.length; o++) {
                                    let requestid;
                                    if (e.detail.data.value) {
                                        requestid = e.detail.data.value
                                    } else {
                                        if (e.detail.data.subtype == 'open2buy') {
                                            requestid = e.detail.data.product_id;
                                        } else {
                                            requestid = e.detail.data.videoid;
                                        }
                                    }
                                    if (String(this.current_session.hashs[o].button) == String(requestid)) {
                                        let url_f = this.current_session.hashs[o].hash;
                                        this.wait(true)
                                        this.startLogHash(this.current_session.hashs[o].id,
                                            ['video'].includes(this.current_session.hashs[o].type)).finally(() => {
                                            root_.wait(false)
                                            switch (this.current_session.hashs[o].type) {
                                                case '2buy':
                                                    var ondeComprar = document.getElementById("ondeComprar");
                                                    ondeComprar.dataset.tobuyHash = url_f;
                                                    ondeComprar.click();
                                                    for (let toBuy of document.getElementsByClassName("lett-to-buy")) {
                                                        var style = document.createElement('style')
                                                        style.innerHTML = ".to-buy-close-button {margin-right: 20px;} .to-buy-close-button svg line{stroke:white;}"
                                                        toBuy.shadowRoot.appendChild(style)
                                                    }
                                                    break;
                                                case 'game':
                                                    root_.openExternalGame(root_.current_session.hashs[o]);
                                                    break;
                                                case 'video':
                                                    root_.unity_video = String(url_f);
                                                    root_.currentHash = root_.current_session.hashs[o];
                                                    break;
                                                case 'go_to_session':
                                                    root_.$router.push({
                                                        name: 'Iframe',
                                                        params: {
                                                            sessionName: url_f,
                                                            eventName: localStorage.getItem("eventmap")
                                                        }
                                                    });
                                                    break;
                                                case 'self':
                                                case 'blank':
                                                    window.open(String(url_f), "_" + root_.current_session.hashs[o].type);
                                                    break;
                                                case 'sku':
                                                    root_.$refs['header_main'].$refs[this.store_active].setProduct(String(url_f));
                                                    break;
                                            }
                                        });
                                    }
                                }
                            }
                            break;
                        case 'live':
                            this.live_title = e.detail.data.live_title;
                            this.live_id = e.detail.data.live_id
                            this.modal_lives = true;
                            break;
                        case "ambient_transition":
                            switch (e.detail.data.local) {
                                case 'atendimento':
                                    this.conteudoHandler();
                                    break;
                                default:
                                    if (e.detail.data.local == 'map') {
                                        this.$router.push({name: 'Map'});
                                    } else {
                                        this.$router.push({
                                            name: 'Iframe',
                                            params: {
                                                sessionName: e.detail.data.local,
                                                eventName: localStorage.getItem("eventmap")
                                            }
                                        });
                                    }
                                    break;
                            }
                            break;
                        case "video_scene_message":
                            this.addTranslucentModal({
                                size: 'sm',
                                title: this.$t('translucent_modal.video_room.title'),
                                content: this.$t('translucent_modal.video_room.content'),
                                confirm_text: this.$t('translucent_modal.video_room.confirm'),
                                cancel_text: this.$t('translucent_modal.video_room.confirm'),
                                can_cancel: false,
                                can_close: false,
                                close_background: false,
                                callback: () => {
                                    this.sendToUnity('SetFirstInputDetected', {data: ""});
                                },
                            });
                            break;
                        case "avia_initialized":
                            var avia_value = JSON.parse(e.detail.data.value);
                            var avia_temp = {}
                            avia_temp[avia_value.unity_id] = avia_value.template_id;
                            this.avia = {...this.avia, ...avia_temp};
                            break;
                        case "voice":
                            if (this.$refs["AviaController"]) {
                                this.currentAvia = e.detail.data.value;
                                this.$refs["AviaController"].find(_ => _.unity_id == e.detail.data.value).start();
                            }
                            break;
                        case "avia_audio_finished":
                            if (this.$refs["AviaController"]) {
                                this.$refs["AviaController"].forEach(_ => _.audioStop());
                            }
                            break;
                        case "avia_close":
                            if (this.$refs["AviaController"]) {
                                this.$refs["AviaController"].forEach(_ => _.close());
                            }
                            break;
                        case  "agora_mic_muted":
                            emitter.emit('unity_message::agora_mic_muted', e.detail.data.value === "True");
                            break;
                        case "quest_lightbox":
                            this.$app.modalI("", e.detail.data.value, ()=>this.sendToUnity("QuestLightboxConcluded"), "OK");
                            break;
                        case "connection_status":
                            this.sendToUnity('SetConnectionStatus', !!(this.$refs?.chat_admin?.connected));
                            break;
                    }
                    break;
                //----- \Unity\ -----/
            }
        },
        /*** REVER PARA USAR WEBSOCKET NA LOG */
        startLogHash(hash_id, multiple = true) {
            return new Promise((resolve, reject) => {
                this.$store.dispatch('update_unity_hash_log', {unity_hash_id: hash_id, log_id: null}).then(() => {
                    this.logHash(multiple).then(() => {
                        resolve()
                    }).catch(() => {
                        reject()
                    })
                })
            })

        },
        logHash(multiple = true) {
            return new Promise((resolve, reject) => {
                if (this.unity_hash_log.unity_hash_id === null) {
                    resolve()
                    return;
                }
                axios
                    .post(this.$url_api + 'api/unity-hash/log', {
                        unity_hash_id: this.unity_hash_log.unity_hash_id,
                        log_id: this.unity_hash_log.log_id
                    }, {headers: this.headers})
                    .then(_ => {
                        //data return data
                        const data = _.data;
                        if (data.success && multiple) {
                            this.$store.dispatch('update_unity_hash_log', {
                                unity_hash_id: data.unity_hash_id,
                                log_id: data.log_id
                            })
                            let time = setTimeout(() => {
                                this.logHash()
                            }, 5000)
                            this.update_time_unity_hash_log(time)
                            resolve()
                            return;
                        }
                        this.reset_unity_hash_log()
                        resolve()
                    }).catch(() => {
                    this.reset_unity_hash_log()
                    reject()
                });
                // resolve();
            })
        },
        loadCheckUserTicketsResponse(obj) {
            let tickets = null;
            let tickets_user = null;
            let tickets_ids = [];

            tickets = Object.values(obj.data.tickets);
            tickets_user = Object.values(obj.data.user_tickets);

            // check if the ticket exists
            for (let i = 0; i < tickets.length; i++) {
                tickets_ids.push(tickets[i].id);
            }
            if (tickets_ids.indexOf(this.ticket_gate_id) < 0) {
                // console.log('TICKET NAO EXISTE');
                this.ticket_gate_doesnt_exist = true;
            }

            // check if user already has the ticket
            if (tickets_user.indexOf(this.ticket_gate_id) !== -1) {
                // console.log('USUARIO JA TEM O TICKET');
                this.user_already_have_ticket = true;
            }

            this.modal_ticket_gate = true;
            //this.$refs["ticketGate"].open();
            this.wait(false);
        },
        loadCheckUserTicketsError(obj) {
            console.log(obj);
            this.wait(false);
        },
        loadCoinsBuyResponse(obj) {
            let packages = null;
            let packages_ids = [];
            packages = Object.values(obj.data);

            // check if the package exists. If exists set this.package_single to be consumed in PackageSingle
            for (let i = 0; i < packages.length; i++) {
                packages_ids.push(packages[i].id);
                if (packages[i].id == this.package_id) {
                    this.package_single = packages[i];
                }
            }
            if (packages_ids.indexOf(this.package_id) < 0) {
                this.package_doesnt_exist = true;
            }

            this.modal_package_single = true;
            this.wait(false);
        },
        loadCoinsBuyError(obj) {
            console.log(obj);
            this.wait(false);
        },
        loadUnityQuestResponse(obj) {
            console.log('QUEST APP.vue', obj.data);
            if (obj.data) {
                this.update_quest(obj.data);
                this.sendToUnity('SetQuestData', obj.data.quest); // currentStep  // nextStep
            }
            this.wait(false);
        },
        loadUnityQuestError(obj) {
            console.log(obj);
            this.wait(false);
        },
        //----- \external\ -----//
        //----- notifications -----//
        getNotifications(change = true) {
            change;
            this.analytics("click_notification");
            if (change) {
                this.notification = !this.notification;
                this.updatePopupNotifications()
            }
            if (this.notification) {
                this.wait(true);
                this.notification_request = true;
                axios
                    .get(this.$url_api + "api/NotificationsList", {headers: this.headers})
                    .then((response) => this.getNotificationsResponse(response))
                    .catch((error) => this.getNotificationsError(error.response));
            }
        },
        getNotificationsResponse(obj) {
            this.wait(false);

            if (obj.data.status == "success") {
                this.notification = true;
                this.update_notification_datas(obj.data.data.notifications)
                this.updatePopupNotifications()
            }
            this.notification_request = false;
        },
        getNotificationsError(obj) {
            obj;
            this.wait(false);
            this.notification_request = false;
        },
        /*

            getNotificationsCount()
            {
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
                axios.get(this.$url_api + 'api/NotificationsCount', { headers:headers }).then(response => this.getNotificationsCountResponse(response)).catch(error => this.getNotificationsCountError(error.response));
            },
            getNotificationsCountResponse(obj)
            {
                if (obj.data.status == 'success') {
                    if (this.TotalCoins != obj.data.data.coins) {
                        this.updateUser('TotalCoins', obj.data.data.coins);
                        this.update_coin(obj.data.data.coins);
                    }
                    if (this.TotalPoints != obj.data.data.points) {
                        this.updateUser('TotalPoints', obj.data.data.points);
                        this.update_point(obj.data.data.points);
                    }
                    this.notifications_count = Number(obj.data.data.count);
                }
            },
            getNotificationsCountError(obj)
            {
                obj;
                this.varifyLoggedError(obj.data);
            },
            //----- \notifications\ -----//
            */

        //----- complaint-chat -----//
        complaintOpenHandler(id_user__) {
            this.complaint = !this.complaint;
            this.complaint_user_id = id_user__;

            //this.$refs['complaint-box'].style.display = ((this.$refs['complaint-box'].style.display == 'flex') ? 'none' : 'flex');
        },
        complaintValueHandler(e) {
            this.$refs["reason"].value = e.currentTarget.value;
        },
        complaintHandler(e) {
            e;
            this.wait(true);
            if (this.$refs["reason"].value.length <= 0) {
                this.modal(
                    "error",
                    this.$i18n.t('app.erro'),
                    this.$i18n.t('app.porFavorSelecione'),
                    null,
                    null,
                    null,
                    "OK"
                );
                this.wait(false);
                return false;
            }
            let form_data = new FormData();
            form_data.append("id_user", this.complaint_user_id);
            if (this.$refs["reason"].value == "Outros") {
                form_data.append("reason", this.$refs["complaint-message"].value);
            } else form_data.append("reason", this.$refs["reason"].value);
            let root = this;
            axios
                .post(this.$url_api + "api/FlagUser", form_data, {headers: this.headers})
                .then((response) => {
                    root.complaintResponse(response)
                    if (root.$route.name == "Friends") {
                        root.$refs['main'].blockUserProcess({id: this.complaint_user_id})
                    }
                })
                .catch((error) => this.complaintError(error.response));
        },
        complaintResponse(obj) {
            if (obj.data.status == "success") {
                this.analytics("flag_user");
                this.modal(
                    "success",
                    this.$i18n.t('app.sucesso'),
                    obj.data.message,
                    null,
                    null,
                    null,
                    "OK"
                );
            }
            this.complaint = false;
            this.wait(false);
        },
        complaintError(obj) {
            if (
                obj.data.message == "erro_desconhecido" ||
                obj.data.status == "error"
            ) {
                let str = "";
                for (let o in obj.data.errors) {
                    str += obj.data.errors[o] + "\n";
                }
                this.modal("error", this.$i18n.t('app.erro'), str, null, null, null, "OK");
            }
            this.complaint = false;
            this.wait(false);
        },
        getRewards() {
            this.wait(true)

            axios.post(this.$url_api + "api/award", {award_slug: this.reward_slug}, {headers: this.headers})
                .then(response => this.getRewardsResponse(response))
                .catch(error => this.getRewardsError(error.response))

        },
        getRewardsResponse(obj) {
            this.reward_message = obj.data.response.message;

            let cta = obj.data.response.url_cta;

            if (this.checkIOS) {
                this.reward_cta_button = obj.data.response.url_cta_ios ?? cta;
            } else if (this.checkMobile) {
                this.reward_cta_button = obj.data.response.url_cta_android ?? cta;
            } else {
                this.reward_cta_button = cta;
            }
            this.reward_cta_label = obj.data.response.label_cta;
            this.modal_rewards = true;

            this.update_reward_slug(null)
            this.wait(false)
        },
        getRewardsError(obj) {
            console.log(obj);
            this.update_reward_slug(null)
            this.wait(false)
        },
        //----- \complaint\ -----//
        requestAddFriend(id) {
            this.wait(true);
            let form_data = new FormData();
            form_data.append('friend_id', id);

            axios.post(`${this.$url_api}api/AddFriend`, form_data, {headers: this.headers})
                .then(response => this.requestAddFriendResponse(response, id))
                .catch(error => this.requestToggleFriendStatusError(error.response));
        },
        requestAddFriendResponse(response, friendId) {
            this.analytics('add_friend');
            if (this.$route.name === "Iframe") {
                this.$refs["chat"].addRelatedFriend(Number(friendId));
            }
            this.wait(false);
        },
        requestToggleFriendStatusError(error) {
            if (error.data.message === 'erro_desconhecido') {
                let str = '';

                for (let i in error.data.errors) {
                    str += `${error.data.errors[i]}\n`;
                }

                this.modal('error', this.$i18n.t('friends.erro'), str, null, null, null, "OK");
            }

            this.wait(false);
        },
        //----- chat/ambient -----//
        redirectTo(target) {
            this.$router.push({path: '/' + target});
        },
        modalsHandler() {
            this.notification = false;
            this.camera = false;
            this.modal_validacao_ = false;
            this.modal_RememberPassword = false;
            this.modal_ResetPassword = false;
            this.modal_PoliticaDePrivacidade = false;
            this.modal_package = false;
            this.modal_package_buy = false;
            this.modal_guest_buy = false;
            this.modal_package_payments = false;
            this.modal_coins_buy = false;
            this.modal_ticket_gate = false;
            this.modal_package_single = false;
            this.modal_private_messages = false;
            this.modal_quest = false;
            if (this.$route.name != "Signup" && this.$refs.SignupModal) {
                this.$refs.SignupModal?.openModal(false);
            }
            if (this.$route.name != "Login" && this.$refs.LoginModal) {
                this.$refs.LoginModal?.openModal(false);
            }
            if (this.$route.name != "TermosPage" && this.$refs.TermosModal) {
                this.$refs.TermosModal?.openModal(false);
            }
            switch (this.$route.name) {
                case "Signup":
                    this.$refs.SignupModal?.openModal(true);
                    break;
                case "Login":
                    this.$refs.LoginModal?.openModal(true);
                    break;
                case "EmailConfirmation":
                    this.modal_validacao_ = true;
                    break;
                case "RememberPassword":
                    this.modal_RememberPassword = true;
                    break;
                case "ResetPassword":
                    this.modal_ResetPassword = true;
                    break;
                case "PoliticaPage":
                    this.modal_PoliticaDePrivacidade = true;
                    break;
                case "TermosPage":
                    this.$refs.TermosModal.openModal(true);
                    break;
                default:
                    var dados = this.$store.getters['signup/cadastro'];
                    for (const dadosKey in dados) {
                        if (dados[dadosKey] && dados[dadosKey].length > 0) {
                            this.$refs.SignupModal?.openModal(true);
                        }
                    }
            }
        },
        //----- \chat/ambient\ -----//
        mobileClose() {
            try {
                var mobile = this.$refs["mobile"].$refs["mobile"];
                mobile.classList.remove("change-mobile-out");
                mobile.classList.remove("change-mobile-in");
                mobile.classList.add("change-mobile-out");
            } catch (e) {
                e;
            }

            let eventName = new Event("closeMenu");
            document.dispatchEvent(eventName);
        },
        setOrientation() {
            /*
                  var orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;


                  if (orientation === "landscape-primary") {
                      console.log("That looks good.");
                  } else if (orientation === "landscape-secondary") {
                      console.log("Mmmh... the screen is upside down!");
                  } else if (orientation === "portrait-secondary" || orientation === "portrait-primary") {
                      console.log("Mmmh... you should rotate your device to landscape");
                  } else if (orientation === undefined) {
                      console.log("The orientation API isn't supported in this browser :(");
                  }
                  */
            /*if (window.innerWidth <= 500 && window.innerHeight > window.innerWidth) {
                this.modal(
                   "error",
                    "",
                    this.$i18n.t('app.porFavorVire'),
                   null,
                    null,
                    null,
                    ""
                )


            } else {
                this.modal_ = false;
            }*/
        },
        async viewVideo(video) {
            let form_data = new FormData();
            form_data.append("video", video);
            try {
                await axios
                    .post(this.$url_api + "api/credit/view_video", form_data, {
                        headers: this.headers,
                    });
            } catch (e) {
                //e
            }
        },
        async getDesign() {
            try {
                console.log(this.tabname);
                const response = await axios.get(this.$url_api + "api/getDesign",
                    {
                        headers: {'language': this.$i18n.locale,},
                        params: {event_name: this.switch.Nome_Aba ?? ""}
                    }
                );

                this.update_design(response.data.design);
                this.headerButtons(response.data.header_buttons);
                this.update_stores(response.data.stores);


                this.logoHandler();
            } catch (error) {
                //e
            }
        },
        mergeDeep(target, source) {
            if (typeof target == "object" && typeof source == "object") {
                for (const key in source) {
                    if (typeof source[key] == "object") {
                        if (!target[key]) target[key] = {};
                        this.mergeDeep(target[key], source[key]);
                    } else {
                        target[key] = source[key];
                    }
                }
            }
            return target;
        },
        getDesignWithPromise() {
            this.getSetupResponseWithPromise().then(() => {
                axios.get(this.$url_api + "api/getDesign",
                    {
                        headers: {'language': this.$i18n.locale,},
                        params: {event_name: this.switch.Nome_Aba ?? ""}
                    }
                ).then(response => {
                    this.$i18n.setLocaleMessage(this.$i18n.locale, this.mergeDeep(this.$i18n.messages[this.$i18n.locale], response.data.translations));
                    this.update_design(response.data.design);
                    this.headerButtons(response.data.header_buttons);
                    this.update_stores(response.data.stores);
                    this.loadManifest(response);
                    this.logoHandler();
                })
            })
        },
        async setDesignCookies() {
            try {
                this.setCookie("design_color_buttons_color", this.design.color.buttons_color);
                this.setCookie("design_color_buttons_color_2nd", this.design.color.buttons_color_2nd)
                this.setCookie("design_color_fonts_color", this.design.color.fonts_color);
                this.setCookie("design_color_font_color_2nd", this.design.color.fonts_color_2nd);
                this.setCookie("design_color_headers_color_logged", this.design.color.headers_color_logged);
                this.setCookie("design_color_chat_bg_color", this.design.color.chat_bg_color);
                this.setCookie("design_color_chat_text_color", this.design.color.chat_text_color);
                this.setCookie("design_color_smartphone_text_color", this.design.color.smartphone_text_color);
                this.setCookie("design_color_smartphone_bg_color", this.design.color.smartphone_bg_color);
                this.setCookie("design_color_chat_bgheader_color", this.design.color.chat_bgheader_color);
                this.setCookie("design_color_headers_color", this.design.color.headers_color);
                this.setCookie("design_color_headers_color_2nd", this.design.color.headers_color_2nd);
                this.setCookie("design_color_highlight_color", this.design.color.highlight_color);
                this.setCookie("design_logo", this.design.logo)
                this.setCookie("design_logo_favicon", this.design.logoFavicon)
                this.setCookie("design_font_font_link", this.design.font.font_link)

                this.setCookie("design_loading_icon", this.design.loading_icon);
                this.setCookie("design_loading_bg_alpha", this.design.loading_bg_alpha);
                this.setCookie("design_loading_bg_color", this.design.loading_bg_color);
                this.setCookie("design_loading_use_mobile", this.design.loading_use_mobile);
                this.setCustomFont();
            } catch (error) {
                console.log('design error =>', error)
            }
        },
        hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        },
        hoverColor(hexColor) {
            let hsl = this.hexToHsl(hexColor);
            if (hsl.l < 50) {
                hsl.l = hsl.l - 10;
            } else {
                hsl.l = hsl.l + 10;
            }
            return this.hslToHex(hsl);
        },
        hexToHsl(hex) {
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            let r = parseInt(result[1], 16);
            let g = parseInt(result[2], 16);
            let b = parseInt(result[3], 16);
            r /= 255, g /= 255, b /= 255;
            let max = Math.max(r, g, b), min = Math.min(r, g, b);
            let h, s, l = (max + min) / 2;
            if (max == min) {
                h = s = 0; // achromatic
            } else {
                let d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                switch (max) {
                    case r:
                        h = (g - b) / d + (g < b ? 6 : 0);
                        break;
                    case g:
                        h = (b - r) / d + 2;
                        break;
                    case b:
                        h = (r - g) / d + 4;
                        break;
                }
                h /= 6;
            }
            s = s * 100;
            s = Math.round(s);
            l = l * 100;
            l = Math.round(l);
            h = Math.round(360 * h);
            return {h: h, s: s, l: l};
        },
        hslToHex(hsl) {
            let {h, s, l} = hsl;
            // Must be fractions of 1
            s /= 100;
            l /= 100;
            let c = (1 - Math.abs(2 * l - 1)) * s,
                x = c * (1 - Math.abs((h / 60) % 2 - 1)),
                m = l - c / 2,
                r = 0,
                g = 0,
                b = 0;
            if (0 <= h && h < 60) {
                r = c;
                g = x;
                b = 0;
            } else if (60 <= h && h < 120) {
                r = x;
                g = c;
                b = 0;
            } else if (120 <= h && h < 180) {
                r = 0;
                g = c;
                b = x;
            } else if (180 <= h && h < 240) {
                r = 0;
                g = x;
                b = c;
            } else if (240 <= h && h < 300) {
                r = x;
                g = 0;
                b = c;
            } else if (300 <= h && h < 360) {
                r = c;
                g = 0;
                b = x;
            }
            let rString = Math.round((r + m) * 255).toString(16);
            let gString = Math.round((g + m) * 255).toString(16);
            let bString = Math.round((b + m) * 255).toString(16);
            if (rString.length === 1) {
                rString = "0" + rString;
            }
            if (gString.length === 1) {
                gString = "0" + gString;
            }
            if (bString.length === 1) {
                bString = "0" + bString;
            }
            return "#" + rString + gString + bString;
        },
        setCustomFont() {
            const customFont = new FontFace("Custom Font", `url(${this.fontLink})`);
            document.fonts.add(customFont);
        },
        changeFavicon() {
            const favicon = document.querySelector('link[rel="icon"]');
            favicon.href = this.logoFavicon_;
        },
        sessionHandler(obj, started = false) {
            this.show_quiz = false;
            this.switch_avatar_new = obj.switch_avatar;
            this.ambient_transition = (this.switch.quiz && obj.quiz);

            if (this.switch.quiz && obj.quiz && (obj.template_type != 'unity' || started)) {
                this.show_quiz = true;
            }
            let chat_max_temp_;
            if (!obj.chat_max && !this.switch.chat_max_krpano) {
                chat_max_temp_ = 100;
            } else
                chat_max_temp_ = obj.chat_max ? obj.chat_max : this.switch.chat_max_krpano;

            this.chat_max = chat_max_temp_;
            if (obj.template_type != 'unity') {
                this.chat_audio = obj.video_chat ? true : false;
                this.chat_participants = obj.chat_participants ? true : false;
                if (obj.live_chat) {
                    this.chat_max = 0;
                    this.chat_room = obj.live_chat;
                } else {
                    this.chat_room = obj.name;
                    for (let o in this.groups) {
                        //if ((this.groups[o].id_room = obj.room_id) && (obj.groups) && (this.groups[o].on) && (this.groups[o].id == this.group_id)) {
                        if ((this.groups[o].id_room = obj.room_id) && (obj.groups) && (this.groups[o].id == this.group_id)) {
                            this.chat_room = (obj.name + '_' + this.groups[o].id);
                            break;
                        }
                    }
                }
            }
            if ((obj.template_type != 'unity' || started)) {
                this.chat_interactive = (this.switch.chat && obj.chat);
                if (obj.template_type != 'unity' && !this.chat_interactive)
                    this.chat_max = 9999999999;
                this.chat = true;
            }
        },
        errorImage(event) {
            event.target.src = logoLoading;
            // console.clear();
        },
        setLocale(lang) {
            this.$i18n.locale = lang;
            this.analytics('change_language', lang)
            localStorage.setItem('language', this.$i18n.locale);
            localStorage.setItem('userSettedLanguage', this.$i18n.locale);  // nova variavel para guardar se o usuario setou outra lingua para comparação com a lingua padrao do evento
            location.reload();
        },
        updateTitle() {
            const aba = (this.switch.Nome_Aba || this.getCookie("switch_Nome_Aba") || "Metaverso")
            //primeira letra maiuscula
            let pageName = aba.charAt(0).toUpperCase() + aba.slice(1)
            if (this.$route.meta.title) {
                document.title = pageName + ' - ' + this.$route.meta.title;
            } else
                document.title = pageName;
        },
        async decryptUnity(keyEncode, ivEncode, cipherEncode) {
            if (!keyEncode || !ivEncode || !cipherEncode) {
                throw new Error('Algum parâmetro está inválido');
            }

            try {
                const key = CryptoJS.enc.Utf8.parse(keyEncode);
                const iv = CryptoJS.enc.Base64.parse(ivEncode);
                const ciphertext = CryptoJS.enc.Base64.parse(cipherEncode);

                const decrypted = CryptoJS.AES.decrypt(
                    {ciphertext: ciphertext},
                    key,
                    {iv: iv}
                ).toString(CryptoJS.enc.Utf8);

                const objDesc = JSON.parse(decrypted);

                return objDesc;
            } catch (error) {
                console.log('Erro ao decodificar JSON:', error.message);

                return null;
            }
        },
        setPopupInfo(popup) {
            this.popupInfo = {
                label_cta: popup.label_cta,
                url_cta: popup.url_cta,
                message: popup.message
            };
        },
        isModalVisible() {
            //console.log('FLUXO NOTIFY POINT 2');
            const popup = this.switch.popup_points.find(item => parseInt(this.TotalPoints) >= parseInt(item.points));
            if (popup) {
                //console.log('FLUXO NOTIFY POINT 3', popup);
                this.setPopupInfo(popup);
                let form_data = new FormData();
                form_data.append('pop_up_id', popup.id);
                axios.post(this.$url_api + "api/score/notify", form_data, {headers: this.headers})
                    .then((response) => this.notifyPointsResponse(response))
                    .catch((error) => this.notifyPointsError(error.response));
            }
        },
        notifyPointsResponse(obj) {
            obj;
            console.log(obj);
            /*this.count_notify_points = 0;
            this.count_notify_points++;
            let payload = JSON.stringify(obj.data.notification);
            console.log('PAYLOAD RESPONSE', this.switch.websockets, this.logged, this.nickname);
            this.$refs.chat_admin.onNotify(payload);*/
        },
        notifyPointsError(obj) {
            obj;
            console.log('ERROR =>', obj);
        },
        loginSocial(data, blockFunction = _ => _, response = this.loginResponse, error = this.loginError) {
            //type, origin, id, email, name
            if ((String(data.email) == '') || (String(data.email).toLowerCase() == 'undefined') || (String(data.email).toLowerCase() == 'null')) {
                this.modal('error', this.$i18n.t('login.erro'), this.$i18n.t('login.EObrigatorio'), null, null, null, "OK");
            } else {
                if (!this.submit) {
                    blockFunction(true);
                    let form_data = new FormData();
                    Object.entries(data).forEach(([key, value]) => {
                        form_data.append(key, value);
                    });
                    let nickname = data.name.split(" ")[0]
                    nickname = nickname.match(/[A-Za-z0-9]*/g).join('').substring(0, 13)
                    if (nickname.length < 5) {
                        nickname += Math.random().toString().replace('.', '').replace('0', '').substring(0, 5)
                    }
                    form_data.append('nickname', nickname);
                    form_data.append('friend_code', this.$route.query.ref ? this.$route.query.ref : '');
                    form_data.append('is_guest', 0);
                    form_data.append('event_name', this.switch.Nome_Aba ?? this.getCookie("switch_Nome_Aba") ?? localStorage.getItem("eventmap") ?? "");
                    axios.post(this.$url_api + 'api/loginSocial', form_data, {headers: {'language': this.$i18n.locale,}})
                        .then(r => response(r, true, data.type, data.origin, r.data.email))
                        .catch(e => error(e.response,));
                }
            }
        },
        loginResponse(obj, social = false, type = 'email', from = "login", email = null) {
            this.wait(false);
            this.$store.dispatch("update_user_origin", from);
            let root = this;
            if (obj.data) {
                this.$store.dispatch("signup/reset");
                this.login_date = new Date();
                if (typeof window._paq == "object") {
                    window._paq.push(["setUserId", email]);
                }
                this.update_email(email);
                this.update_checkers(0);
                this.update_email_verified(obj.data.userValidated);
                this.update_type_validation(obj.data.check_by_sms ? "sms" : "");
                this.update_token(obj.data.token);
                this.update_logged(true);
                this.update_id(obj.data.id);
                this.update_login_social(social);
                this.update_age_verified(!!obj.data.birth_day);
                this.axiosSetup();
                if (!social) {
                    this.analytics(from + '_email');
                } else {
                    this.analytics(from + '_' + type);
                }

                this.createHTMLTemplate(null);
                this.getUser().then(() => {
                    if (obj.data.userValidated) {
                        // if(from == 'signup') {
                        //     this.$router.push({path: '/lms/letzgo/checkout/add-to-cart?object=coursePackage&object_id=1'});
                        //     console.log('redirecionou')
                        // } else {
                        if (localStorage.getItem('redirectPathEventLink')) {
                            let url_temp__ = localStorage.getItem('redirectPathEventLink');
                            localStorage.removeItem('redirectPathEventLink');
                            if (this.$route.name !== 'Iframe' || this.$route.params.sessionName !== url_temp__)
                                this.$router.push({name: 'Iframe', params: {sessionName: url_temp__, eventName: localStorage.getItem("eventmap")}});
                        } else if (localStorage.getItem('redirectPath')) {
                            this.$router.push({path: localStorage.getItem('redirectPath')});
                            localStorage.removeItem('redirectPath');
                        } else if (this.$route.name !== "Iframe") {
                            this.link('Map');
                        }
                        localStorage.setItem('loggedInWorld', root.switch.Nome_Aba)

                    } else
                        this.link('EmailConfirmation');
                });
            } else if (obj.data.error) {
                this.error = obj.data.error;
                this.modal('error', this.$i18n.t('login.erro'), this.error, null, null, null, "OK");
            } else if ((obj.data === "") || (obj.data === null) || (obj.data === "null")) {
                this.error = this.$i18n.t('login.ocorreuUmErro');
                this.modal('error', this.$i18n.t('login.erro'), this.error, null, null, null, "OK");
            }
        },
        loginError(obj) {
            this.wait(false);
            let str = '';
            for (let o in obj.data.errors) {
                str += obj.data.errors[o].charAt(0).toUpperCase() + obj.data.errors[o].slice(1) + '\n';
            }
            this.modal('error', this.$i18n.t('login.erro'), str, null, null, null, "OK");
        },
        async selfie(img_) {
            this.wait(true);
            const response = await axios.post(this.$url_api + 'api/selfie', {
                selfie: img_
            }, {
                headers: this.headers,
            })
            if (response.data) {
                this.$refs['selfieControl'].openInsideModal('selfies', true, response.data.selfie.id);
            }
        },
        redirectToMap() {
            if (this.$route.name === 'Map') {
                window.location.reload();
            } else {
                this.link('Map');
            }
        },
        aviaHandler(iframe, data) {
            console.log('aviaHandler => ', data);
            switch (data.data.subtype) {
                case 'avia-close':
                    // this.currentAvia = e.detail.data.value;
                    // this.$refs["AviaController"].find(_ => _.unity_id == e.detail.data.value).start();
                    // if (this.$refs["AviaController"]) {
                    //     this.$refs["AviaController"].forEach(_ => _.audioStop());
                    // }
                    if (this.$refs["AviaController"])
                    {
                        this.$refs["AviaController"].forEach(_ => _.close());
                    }
                break;
                case 'enter-balloon':
                    if (this.$refs["AviaController"])
                    {
                        this.$refs["AviaController"].forEach(_ => _.enterBalloon());
                    }
                break;
                case 'avia-unity-data':
                    if (this.$refs["AviaController"])
                    {
                        this.$refs["AviaController"].forEach(_ => _.audio(data.data.unityData));
                    }
                break;
                case 'video':
                    this.unity_video = String(data.data.url);
                    break;
                case 'user':
                    // iframe.postMessage({
                    //     message_type: "user_id",
                    //     user_id: this.id
                    // }, "*");
                    iframe.postMessage( { message_type: 'external', type: 'ai_message', subtype: 'user_id',  user_id: this.id }, "*");
                break;
                case 'chatgpt':
                    if
                    (
                        (String(data.data.url).toLowerCase().indexOf('.jpg') !== -1) ||
                        (String(data.data.url).toLowerCase().indexOf('.jpeg') !== -1) ||
                        (String(data.data.url).toLowerCase().indexOf('.png') !== -1) ||
                        (String(data.data.url).toLowerCase().indexOf('.pdf') !== -1) ||
                        (String(data.data.url).toLowerCase().indexOf('.webp') !== -1) ||
                        (String(data.data.url).toLowerCase().indexOf('.mp4') !== -1) ||
                        (String(data.data.url).toLowerCase().indexOf('.avi') !== -1) ||
                        (String(data.data.url).toLowerCase().indexOf('.mov') !== -1) ||
                        ((String(data.data.url).toLowerCase().indexOf('youtube') !== -1) && (data.data.url.toLowerCase().indexOf('embed') !== -1)) ||
                        (String(data.data.url).toLowerCase().indexOf('.webm') !== -1)
                    )
                    {
                        this.unity_video = String(data.data.url);
                    }
                    else
                    {
                        window.open(String(data.data.url), '_blank').focus();
                    }
                break;
            }

        },
        onResize() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
            this.windowWidth = window.innerWidth;
            if(this.iframe_temp)
                this.updateIframeTempCss()
            //set vuex user agent
            this.$store.dispatch('update_user_agent', userAgent);
        },
        updateIframeTempCss() {
            let iframe = document.getElementById('iframe');
            let iframeOffsetWidth = iframe.offsetWidth;
            let iframeOffsetHeight = iframe.offsetHeight;
            const IMAGE_HEIGHT = 720;
            const VIDEO_WIDTH = 534;
            const VIDEO_HEIGHT = 300;
            const ratio = VIDEO_WIDTH/VIDEO_HEIGHT;
            //calcula a height relativa a imagem e o width relativo ao aspect ratio do video
            let height = VIDEO_HEIGHT/IMAGE_HEIGHT*iframeOffsetHeight;
            let width = height*ratio;
            let lastHeight = height;
            //se for maior colocamos o maximo de 95% do tamanho do iframte
            if(width>iframeOffsetWidth){
                width = iframeOffsetWidth * 0.95;
                height = width/ratio;
            }
            let top = 75;
            if (iframeOffsetWidth < 1000){
                top = 125;
            }
            //calculoamos a altura relativa na imagem
            top += (iframeOffsetHeight - height - (lastHeight-height)) / 2;
            this.iframe_temp_css =  {
                position: "absolute",
                top: `${top}px`,
                left: "50%",
                transform: "translate(-50%,-50%)",
                width: `${width}px`,
                height: `${height}px`,
            };
        },
        axiosSetup() {
            let root__ = this;
            axios.interceptors.request.use(function (config) {
                let nome_aba = root__.switch.Nome_Aba ?? root__.getCookie("switch_Nome_Aba") ?? localStorage.getItem("eventmap") ?? "";
                config.headers.eventmap = nome_aba;
                config.headers.pevent = root__.getCookie(`password_event_${nome_aba}`);
                return config;
            }, function (err) {
                return Promise.reject(err);
            });
            axios.interceptors.response.use(function (response) {
                return response;
            }, function (err) {
                if (err.response.status === 401 && err.response.data.can_enter) {
                    let user = localStorage.getItem('user');
                    if (user) {
                        user = JSON.parse(user);
                    } else {
                        user = {
                            id: root__.id,
                            token: root__.token,
                            email: root__.email,
                            email_verified: root__.email_verified,
                            switch: root__.switch,
                            design: root__.design,
                        };
                    }
                    root__.saveUser(user);
                    switch (err.response.data.message) {
                        case 'need_pass':
                            root__.addTranslucentModal({
                                size: 'sm',
                                title: root__.$t('translucent_modal.need_pass.title'),
                                content: root__.$t('translucent_modal.need_pass.content'),
                                confirm_text: root__.$t('translucent_modal.need_pass.confirm'),
                                cancel_text: root__.$t('translucent_modal.need_pass.cancel'),
                                with_input: true,
                                input_placeholder: root__.$t('translucent_modal.need_pass.input_placeholder'),
                                input_type: 'password',
                                can_close: false,
                                close_background: false,
                                background: true,
                                callback: (modalInfo, value) => {
                                    root__.setCookie(`password_event_${root__.switch.Nome_Aba}`, value)
                                    location.reload()
                                },
                                callback_cancel: () => {
                                    localStorage.removeItem('user')
                                    if ((localStorage.getItem("eventmap")) && (localStorage.getItem("eventmap") != '')) {
                                        window.location.href = root__.$url + '#/' + localStorage.getItem("eventmap");
                                    } else {
                                        location.reload();
                                    }
                                },
                            })
                            root__.wait(false)
                            return;
                        case "not_pass":
                            root__.addTranslucentModal({
                                size: 'sm',
                                title: root__.$t('translucent_modal.not_pass.title'),
                                content: root__.$t('translucent_modal.not_pass.content'),
                                confirm_text: root__.$t('translucent_modal.not_pass.confirm'),
                                cancel_text: root__.$t('translucent_modal.not_pass.cancel'),
                                can_cancel: false,
                                can_close: false,
                                close_background: false,
                                background: true,
                                callback: () => {
                                    root__.deleteCookie(`password_event_${root__.switch.Nome_Aba}`)
                                    location.reload()
                                },
                            });
                            root__.wait(false)
                            return;
                        case 'not_found':
                        case 'not_email':
                            root__.addTranslucentModal({
                                size: 'sm',
                                title: root__.$t('translucent_modal.not_email.title'),
                                content: root__.$t('translucent_modal.not_email.content'),
                                confirm_text: root__.$t('translucent_modal.not_email.confirm'),
                                can_cancel: false,
                                can_close: false,
                                close_background: false,
                                background: true,
                                callback: () => {
                                    localStorage.removeItem('user')
                                    if ((localStorage.getItem("eventmap")) && (localStorage.getItem("eventmap") != '')) {
                                        window.location.href = root__.$url + '#/' + localStorage.getItem("eventmap");
                                    } else {
                                        location.reload();
                                    }
                                },
                            });
                            root__.wait(false)
                            return;
                    }
                }
                return Promise.reject(err);
            });
        },
        notFound(){
            this.notFoundError = true;
        },
    },
    beforeMount() {
        let route = this.$route.params;
        if (route?.lmsSlug) {
            this.$store.dispatch('LMS/getListLMS', {slug: route.lmsSlug})
                .then(() => {
                    document.body.classList.add('lms');
                    document.body.classList.add('transparent');
                });
        }
    },
    async mounted() {
        let root__ = this;
        root__.axiosSetup();
        if (!root__.switch.log) {
            console.log = function () {
            }
        }
        // console.log('openchaatBUTTON =>', root__.openChatButton)
        let queryTrack = {};
        for (const urlSearchParam of new URLSearchParams(window.location.search)) {
            queryTrack[urlSearchParam[0]] = urlSearchParam[1];
        }
        if (Object.entries(queryTrack).length > 0) {
            localStorage.setItem('query_tracking', JSON.stringify(queryTrack));
        } else if (Object.keys(this.$route.query).length > 0) {
            localStorage.setItem('query_tracking', JSON.stringify(this.$route.query));
        }
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
        window.addEventListener("mousemove", function () {
            root__.count_inactivity = 0;
        });
        window.addEventListener("message", this.finishForm)
        document.addEventListener("touchstart", function () {
            root__.count_inactivity = 0;
        });
        if (this.timer === false) {
            this.timer = true;
            clearInterval(this.interval);
            clearInterval(this.focusUnityInterval)
            this.focusUnityInterval = setInterval(function () {
                let focus = false;
                if (document.activeElement == document.getElementById("iframe")) {
                    focus = true;
                }
                if (focus != root__.focusUnity) {
                    root__.focusUnity = focus;
                    root__.sendToUnity('SetFocusOnUnity', focus);
                }
            }, 1000);
            this.interval = setInterval(function () {
                if (root__.logged || root__.isGuest) root__.checkInactivity();
            }, 1000);
        }
        /*
            try
            {
                screen.lockOrientation('portrait');
            } catch(e) {
                //
            }
            */
        window.addEventListener("orientationchange", function (event) {
            event;
            root__.setOrientation();
        });
        if (isWebview(navigator.userAgent)) {
            this.update_webview(true);
        }
        //document.body.setAttribute("transform:rotate("+rotate+"deg);-ms-transform:rotate("+rotate+"deg);-webkit-transform:rotate("+rotate+"deg)", "style");
        // deixar comentado por hora
        switch (this.$route.name) {
            case "Iframe":
                /*
                        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
                            alert("Ambientes avatar 3D para iOs está em ajustes.\nEm breve estará regularizado. Neste momento, para uma experiência completa, acesse via desktop ou Android.");
                        }
                        */
                /* const gl = document.createElement('canvas').getContext('webgl2');
                         if (!gl) {
                             this.webGl2 = false;
                             alert('Seu dispositivo não é compatível com algumas funcionalidades da plataforma.');
                         } else {
                             this.webGl2 = true;
                             //console.log('webgl2 works!');
                         }*/
                break;
        }
        if (localStorage.getItem("user")) {
            // se logado pega o setup pelo usuário
            await this.getUser();
        } else
            await this.getSetupWithPromise();

        document.addEventListener("closeMenu", () => {
            try {
                this.menu_active = false;
                document.getElementById("menu-icon").classList.remove("change");
                document.getElementById("menu").style.display = "none";
            } catch (e) {
                //
            }
        });
        // await this.getDesign();
        this.getDesignWithPromise();
        this.modalsHandler();
        this.wizardHandler();
        this.aoVivoHandler();
        await this.setDesignCookies();


        switch (true) {
            case !!this.store_vt:
                this.store_active = "vt"
                break;
            case !!this.store_vtex:
                this.store_active = "vtex"
                break;
        }

        if (this.isGuest) {
            this.switch.btn_promotional.active = false
        }
        if (this.switch.without_landing_page == 'without_landing_page' && localStorage.getItem('user') == null) {
            this.createGuest();
        }

        let uri_qrcode = this.$route.fullPath.split('?')[1];
        let params_qrcode = new URLSearchParams(uri_qrcode);
        if (params_qrcode.get('tab') == 'logout') {
            this.logout();
        } else if (params_qrcode.get('award')) {
            this.update_reward_slug(params_qrcode.get('award'))
            if (this.logged || this.isGuest) {
                this.getRewards()
            } else {
                this.$refs["LoginModal"].openModal(true)
            }
        }

        if (this.logged || this.isGuest) {
            console.log('FLUXO NOTIFY POINT 1');
            this.isModalVisible();
            /*if (this.isModalVisible) {
                this.showPopup();
            }*/
            // this.$store.dispatch('LMS/update_lms',);
        }

        console.log('started =>', root__.started)
        console.log('chat =>', root__.chat);

        emitter.on('root::loader', (status) => {
            root__.loading = status;
        })

        emitter.on('Minigames::ranking::modal', payload => {
            root__.showRankingModal = payload;
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        emitter.off('Minigames::ranking::modal');
        localStorage.removeItem("minigame_id");
        localStorage.removeItem("session_name");
        localStorage.removeItem("background_url");
    },
    created() {
        Vue.prototype.$app = this;
        this.update_started(false);
        //console.log'Versão => 14/05/2022 17:44 => ', this.$route.params);
        if (this.$route.params.eventName || this.$route.name == 'EventMap') {
            localStorage.setItem('eventmap', this.$route.params.eventName);
        }


        if (localStorage.getItem("user")) {
            //console.log'created local storage user');
            let user = JSON.parse(localStorage.getItem("user"));
            if (localStorage.getItem('guest')) {
                this.update_guest(true)
                this.update_logged(false);
            } else {
                this.update_logged(true);
            }
            this.update_id(user.id);
            this.update_token(user.token);
            this.update_email(user.email);
            this.update_age_verified(user.birth_day == false ? false : true);
            this.update_email_verified(user.email_verified);
            this.update_profile(user);
            // this.LMSHandler(user.lms ? true : false, 'app', ((this.$route.name == 'EventMap') ? this.$route.params.eventName : ''), ((this.$route.name == 'EventLink') ? this.$route.params.sessionName : ''));
            // this.LMSHandler(user.lms??false, 'app', ((this.$route.name == 'EventMap') ? this.$route.params.eventName : ''), ((this.$route.name == 'EventLink') ? this.$route.params.sessionName : ''));

            if (localStorage.getItem('redirectPathEventLink')) {
                let url_temp__ = localStorage.getItem('redirectPathEventLink');
                localStorage.removeItem('redirectPathEventLink');

                this.$router.push({
                    name: 'Iframe',
                    params: {sessionName: url_temp__, eventName: localStorage.getItem("eventmap")}
                });

                //return;
            } else if (this.$route.name == 'EventMap') {
                this.link('Map');
            }
        }else if (this.$route.name == 'Iframe') {
            localStorage.setItem('redirectPathEventLink', this.$route.params.sessionName);
        }

        this.logoHandler();
        //console.log'this.logged, this.$route => ', this.logged, this.$route);
        // this.detectScreenSizeChange();
        if ((this.$route.name == "EventMap") || (!this.logged && !this.isGuest && this.$route.name == "Iframe")) {
            //
        } else if (!this.logged && !this.isGuest && this.$route.meta.requiresLogin == true) {
            if (this.$route.name != "EmailConfirmation") {
                localStorage.setItem("redirectPath", this.$route.fullPath)
            }
            this.link("Home");
            this.$refs["LoginModal"].openModal(true)
        } else if (!this.email_verified && this.$route.meta.requiresEmail == true) {
            if ((this.$route.name != "EmailConfirmation") && (this.$route.name != "EmailConfirmationCode")) {
                localStorage.setItem("redirectPath", this.$route.fullPath)
                this.link("EmailConfirmation");
            }
        } else if (!this.age_verified && this.$route.meta.requiresAge == true) {
            if (this.$route.name != "Age") {
                this.link("Age");
            }
        } else if ((this.logged || this.isGuest) && this.$route.meta.requiresLogin == false) {
            if (!this.access_logged.includes(this.$route.name)) {
                this.link("Map")
            }
        } else if ((this.logged || this.isGuest) && this.switch.validate_user) {
            if (this.$route.name !== "Map"){
                this.link("Map");
            }
        } else if (this.age_verified && this.$route.name == "Age") {
            this.link("Map");
        }
        let ext = document.createElement("script");
        ext.setAttribute("src", this.$url + "js/ext.js");
        document.head.appendChild(ext);
        document.addEventListener("external", this.externalEventHandler);
        window.addEventListener(
            "message",
            (event) => {
                switch (event.data.message_type) {
                    case "token":
                        try {
                            event.source.postMessage({
                                type: "token",
                                token: this.token,
                                lang: this.$i18n.locale,
                                eventName: this.switch.Nome_Aba,
                            }, "*");
                        } catch ({error}) {
                            console.log('token error');
                        }
                        break;
                    case "external":
                        this.externalEventHandler({detail: {data: event.data.data}});
                        break;
                    case "avia":
                        this.aviaHandler(event.source, event.data);
                    break;
                }
            },
            false,
        )
    },
    updated() {
        this.updateTitle();
    },
    watch: {
        'switch.Nome_Aba': function (val, oldVal) {
            if (val != oldVal) {
                this.axiosSetup();
            }
        },
        cssVars: {
            handler() {
                document.body.style = Object.entries(this.cssVars).map(_ => _.join(":")).join(";");
            },
            deep: true,
        },
        current_session() {
            this.iframe_ready = true;
            this.wizardHandler();
        },

        $route(to, from) {
            this.notFoundError = false;
            this.iframe_ready = false;
            this.avia = {}
            this.updateTitle();
            this.currentHash = null;
            //console.log'watch => ', to.name, from.name);
            if (!this.logged && !this.isGuest && to.meta.requiresLogin == true && to.name !== "Iframe") {
                console.log('caindo no redirect do app')
                let uri_qrcode = to.fullPath.split('?')[1];
                let params_qrcode = new URLSearchParams(uri_qrcode);
                if (params_qrcode.get('award')) {
                    this.update_reward_slug(params_qrcode.get('award'))
                }
                this.link("Home");
                //this.$refs["LoginModal"].openModal(true)
            }
            if (to.params.eventName && localStorage.getItem("eventmap") != to.params.eventName) {
                localStorage.setItem("eventmap", to.params.eventName);
                window.location.reload();
            }
            switch (from.name) {
                case "Iframe":
                    if (["unity","avia"].includes(this.current_session.template_type)) {
                        window.location.reload();
                        if (document.getElementById('iframe'))
                            document.getElementById('iframe').remove();
                    }
                    this.chat = false;
                    break;
                // case 'Home':
                case 'Support':
                    try {
                        this.$zendesk.hide();
                    } catch (e) {
                        e;
                    }
                    break;
            }
            switch (to.name) {
                case "Iframe":
                    this.chat = false;
                    break;
                // case 'Home':
                case 'Support':
                    try {
                        this.$zendesk.show();
                    } catch (e) {
                        e;
                    }
                    break;
            }
            if (this.chat_not_clear) {
                this.chat_not_clear = false;
            } else {
                this.chat_not_clear = false;
                this.chat_room = "";
                this.chat_owner = "";
            }

            this.iframe_temp = false;
            this.switch_avatar_new = false;
            this.bodyOverflow(false);
            this.modalsHandler();
            this.wizardHandler();
            this.aoVivoHandler();
            if (!this.logged && !this.isGuest && to.name === "Iframe") {
                //not logged iframe page
            } else if (!this.email_verified && to.meta.requiresEmail == true) {
                if (this.$route.name != "EmailConfirmation") {
                    localStorage.setItem("redirectPath", to.fullPath)
                    this.link("EmailConfirmation");
                }
            } else if (!this.age_verified && to.meta.requiresAge == true) {
                if (to.name != "Age") {
                    this.link("Age");
                }
            } else if (this.logged && to.meta.requiresLogin == false) {
                if (!this.access_logged.includes(to.name)) {
                    this.link("Map");
                }
            } else if (this.logged && this.switch.validate_user) {
                if (to.name !== "Map") {
                    this.link("Map");
                }
            }
            //location.reload();


            //this.bodyOverflow(false);
            //this.modalsHandler();
        },
        windowWidth(windowWidth) {
            if (windowWidth < 1000) {
                this.tools = false;
            } else {
                this.tools = true;
            }
        },
        logoUrl() {
            return this.logoUrl;
        },
        // reward_slug() {
        //     if (this.reward_slug != '' && (this.logged || this.isGuest)) {
        //         this.getRewards()
        //     }
        // },
        logged() {
            if (this.logged && this.reward_slug) {
                this.getRewards()
            }
        },
        isGuest() {
            if (this.isGuest && this.reward_slug) {
                this.getRewards()
            }
        }
    },
};
</script>

<style lang="scss">
@import "/src/assets/scss/app.scss";

.lms #app {
    margin-bottom: 50px;
}

.container {
    max-width: 87.5% !important;
    min-width: 87.5% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.copyright_footer_lms {
    position: absolute;
    align-items: center;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    background-color: #e1e1e1;
    color: #132132;
    text-align: center;
    padding: 5px 15px;
    width: calc(100%);
    z-index: 5;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;

    img {
        height: 20px;
        object-fit: contain;
    }

    p {
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 10px;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }
}

.mobileNotifications {
    animation: pulse 1s infinite;
    transform-origin: center;
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.8);
    }
}

.toolsMobile div {
    margin-top: 8px;
}

.toolsBackdrop {
    background-color: rgba(0, 0, 0, .8);
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
}

.text-nickname {
    text-decoration: underline;
    font-size: 12px !important;
}

#chat_owner {
    background-color: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    color: white;
    font-family: "Roboto";
    font-size: 12px;
    right: 0;
    padding: 8px;
    position: absolute;
    text-transform: uppercase;
    top: 130px;
}

.aovivo {
    background: #d91a32 !important;
    color: white !important;
    right: 245px !important;
    animation: aovivo 3s infinite alternate;
}

.aovivo p:hover {
    color: white !important;
}

@keyframes aovivo {
    0% {
        box-shadow: none;
    }
    33% {
        box-shadow: 0 0 7px 4px #d91a32;
    }
    100% {
        box-shadow: none;
    }
}

@keyframes aovivo_menor {
    0% {
        box-shadow: none;
    }
    33% {
        box-shadow: 0 0 4px 2px #d91a32;
    }
    100% {
        box-shadow: none;
    }
}

#play-pause-fullscreen {
    bottom: 90px;
    right: 10px;
    position: fixed;
}

#play-pause-fullscreen li {
    box-sizing: border-box;
    list-style: none;
    padding: 0 0 5px 0;
}

#play-pause-fullscreen img {
    filter: grayscale(1) invert(1);
    width: 40px;
}

@media screen and (max-width: 800px) {
    /*#iframe_temp
      {
          left: 10px !important;
          width: calc(100% - 20px) !important;
      }*/
    .aovivo {
        top: 360px;
        right: 0 !important;
    }
}

#webcam-photo {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    display: none;
    font-family: "Roboto";
    font-size: 12px;
    height: 100%;
    left: 0;
    letter-spacing: 2px;
    position: fixed;
    top: 0;
    width: 100%;
}

#webcam-photo > #webcam-photo-content {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    display: block;
    height: auto;
    margin: 100px auto;
    position: relative;
    text-align: left;
    width: 522px;
}

#webcam-photo-header {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 8px 8px 0px 0px;
    box-sizing: border-box;
    display: block;
    height: 57px;
    padding: 22px 15px;
    width: 100%;
}

#webcam-photo > #webcam-photo-content > #webcam-photo-video {
    background-color: black;
    background-image: url("../public/assets/img/webcam/icon_photo.png");
    background-repeat: no-repeat;

    background-position: center;
    border-radius: 8px;
    margin-left: 15px;
    margin-top: 15px;
    width: calc(100% - 30px);
    height: 278px;
}

#webcam-photo > #webcam-photo-content > #webcam-photo-canvas {
    border-radius: 8px;
    height: 278px;
    left: 0;
    margin-left: 15px;
    margin-top: 72px;
    position: absolute;
    top: 0;

    /*width: calc(100% - 30px);*/

    height: 278px;
    width: calc(100% - 30px);
    object-fit: cover;
    object-position: center;
}

#pointsIsMobile {
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    top: 20px;
    right: 5px;
}

#pointsIsMobile p {
    margin: 0;
    padding: 0.5rem;
}

#pointsIsMobile #point {
    color: var(--buttons_color);
    min-width: 70px;
    margin-right: 10px;
    padding: 0 5px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid var(--buttons_color);
}

#pointsIsMobile #coin {
    color: #fff;
    margin-right: 10px;
    padding: 0 5px;
    border-radius: 5px;
    min-width: 70px;
    background-color: var(--buttons_color);
    border: 1px solid var(--buttons_color);
}

#webcam-photo-discard {
    display: none;
    left: 25px;
    position: absolute;
    top: var(--headers_height);
}

#webcam-photo-save {
    display: none;
}

#webcam-photo .fr {
    float: right;
}

#webcam-btns {
    display: block;
    text-align: center;
}

.vs__search {
    padding: 0 !important;
    margin: 0 !important;
    width: 1px;
    height: 1px;
    position: absolute;
}

.vs__actions {
    display: none !important;
}

.v-select {
    padding: 7px;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.vs__selected {
    padding: 1px 0 0 0;
    margin: 0;
    border: none;
    position: relative !important;
}

.vs__dropdown-toggle {
    background-color: var(--multi_language_background);
    border-color: var(--buttons_color);
    padding: 0;
    border: none;
}

.vs__dropdown-menu {
    min-width: 32px;
    border-color: var(--buttons_color);
    margin-left: -1px;
}

.vs__dropdown-option {
    padding: 0;
    justify-content: center;
    display: flex;
    margin-top: 5px;
}

.vs__selected-options {
    padding: 0;
}

.vs--open {
    padding: 7px;
    border: 1px solid var(--buttons_color);
    border-radius: 4px 4px 0 0;
}

.add_quasar .q-focus-helper {
    width: 0;
}

.add_quasar .q-item-type {
    width: 462px;
}

.add_quasar .q-avatar {
    width: .5em;
}

.q-expansion-item.q-item-type.q-expansion-item--collapsed.q-expansion-item--standard {
    width: 450px;
}

.q-expansion-item.q-item-type.q-expansion-item--expanded.q-expansion-item--standard {
    width: 450px;
}

.add_quasar .column, .add_quasar .flex, .add_quasar .row {
    justify-content: center;
}

/*----- loader -----*/
/*
    #loader
    {
        background-color: rgba(0, 0, 0, .8);
        display         : none;
        height          : 100%;
        left            : 0;
        position        : fixed;
        top             : 0;
        width           : 100%;
        z-index         : 99999;
    }
    #loader-ani
    {
        -webkit-animation: spin 2s linear infinite;
        animation        : spin 2s linear infinite;
        border           : 6px solid #f5f5f5;
        border-radius    : 50%;
        border-top       : 6px solid #13b5eb;
        height           : 40px;
        left             : 50%;
        margin-left      : -20px;
        margin-top       : -20px;
        position         : fixed;
        top              : 50%;
        width            : 40px;
    }

    @media screen and (max-width: 399px) {
        #audioPlayers img{
            max-width: 35px;
        }
    }

    @media screen and (min-width: 400px) {
        #audioPlayers img{
            max-width: 45px;
        }
    }

    @media screen and (min-width: 800px){
        #audioPlayers img{
            max-width: 55px;
        }
    }

    @media (max-width: 1000px) {
        #pointsIsMobile {
            position: static;
        }
        #pointsIsMobile #coin {
            height: 35px;
        }
        #pointsIsMobile #point {
            height: 35px;
        }
    }

    @-webkit-keyframes spin
    {
        0%   { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin
    {
        0%   { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    */
/*----- \loader\ -----*/
.new_signin {
    .button {
        background-color: var(--buttons_color);
    }
}
</style>
