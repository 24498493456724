import i18n from '@/i18n';

export default {
    lms: {},
    course: {},
    section: {
        "id": 0,
        "course_id": 0,
        "file_id": 0,
        "title": "Loading...",
        "slug": null,
        "description": null,
        "order": 0,
        "credit_coins": null,
        "active": true,
        "created_at": "2023-08-09T23:18:27.000000Z",
        "updated_at": "2023-08-09T23:18:37.000000Z",
        "deleted_at": null,
        "thumbnail": "https://api-local.virtual.town/storage/0b9af57a077ece065910cda8e38af610.png",
        "total_lessons": 0,
        "is_fav": false,
        "lessons": [{
            "id": 0,
            "section_id": 0,
            "file_id": null,
            "title": "Loading...",
            "slug": null,
            "description": null,
            "order": 0,
            "credit_coins": null,
            "active": true,
            "created_at": "2023-08-10T13:14:08.000000Z",
            "updated_at": "2023-08-10T13:27:50.000000Z",
            "deleted_at": null,
            "is_finished": false,
            "thumbnail": null,
            "total_contents": 0,
            "is_fav": false
        }]
    },
    courses: {},
    userInformation: {},
    selectedLesson: {
        id: 0
    },
    welcomeUser: {},
    userCourses: [],
    userClasses: [],
    slideshow: {},
    communities: {},
    continue_journey: {},
    lesson: {},
    notes: [],
    isLms: false,
    pages: {
        LmsStudentBook: {
            pageName: "Book do Aluno",
            navigationTitle: "Book do Aluno"
        },
        LmsCheckout: {
            pageName: "Pagamento",
            navigationTitle: "Pagamento"
        },
        LmsLibrary: {
            pageName: "Biblioteca",
            navigationTitle: "Biblioteca"
        },
        LmsCertificates: {
            pageName: 'Certificados',
            navigationTitle: 'Certificados',
            navigation: [
                // {
                //     name: "Fórum de discussão",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Ranking",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Turmas",
                //     slug: "",
                //     icon: ""
                // },
                {
                   name: "Feed Social",
                   slug: "LmsFeedSocial",
                   icon: "bi bi-people-fill"
                },
                {
                    name: "Meu perfil",
                    slug: "LmsUserProfile",
                    icon: "bi bi-person-fill"
                },
                {
                    name: "Book do aluno",
                    slug: "LmsStudentBook",
                    icon: "bi bi-book-half"
                }
            ]
        },
        LmsStudentClasses: {
            pageName: 'Turmas',
            navigationTitle: 'Turmas',
            navigation: [
                // {
                //     name: "Fórum de discussão",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Ranking",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Turmas",
                //     slug: "",
                //     icon: ""
                // },
                {
                   name: "Feed Social",
                   slug: "LmsFeedSocial",
                   icon: "bi bi-people-fill"
                },
                {
                    name: "Meu perfil",
                    slug: "LmsUserProfile",
                    icon: "bi bi-person-fill"
                },
                {
                    name: "Book do aluno",
                    slug: "LmsStudentBook",
                    icon: "bi bi-book-half"
                }
            ]
        },
        LmsStudentProgress: {
            pageName: "Meu Cockpit",
            navigationTitle: "Minhas comunidades",
            navigation: [
                // {
                //     name: "Fórum de discussão",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Ranking",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Turmas",
                //     slug: "",
                //     icon: ""
                // },
                {
                   name: "Feed Social",
                   slug: "LmsFeedSocial",
                   icon: "bi bi-people-fill"
                },
                {
                    name: "Meu perfil",
                    slug: "LmsUserProfile",
                    icon: "bi bi-person-fill"
                },
                {
                    name: "Book do aluno",
                    slug: "LmsStudentBook",
                    icon: "bi bi-book-half"
                }
            ]
        },
        LmsPayments: {
            pageName: "Financeiro",
            navigation: [
                // {
                //     name: i18n.t('lms.payments.navigation.my_wallet'),
                //     slug: "my-wallet",
                //     icon: ""
                // },
                {
                    name: i18n.t('lms.payments.navigation.payment_history'),
                    slug: "payment-history",
                    icon: ""
                },
            ]

        },
        LmsFriends: {
            pageName: "Amigos",
            navigationTitle: "Minhas Comunidades",
            navigation: [
                // {
                //     name: "Fórum de discussão",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Ranking",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Turmas",
                //     slug: "",
                //     icon: ""
                // },
                {
                   name: "Feed Social",
                   slug: "LmsFeedSocial",
                   icon: "bi bi-people-fill"
                },
                {
                    name: "Meu perfil",
                    slug: "LmsUserProfile",
                    icon: "bi bi-person-fill"
                },
                {
                    name: "Book do aluno",
                    slug: "LmsStudentBook",
                    icon: "bi bi-book-half"
                }
            ]
        },
        LmsCommunityFeed: {
            "pageName": "Comunidade",
            "navigationTitle": "Minhas Comunidades",
            navigation: [
                {
                    name: "Minhas Publicações",
                    slug: "LmsMyFeedSocial",
                    icon: "bi bi-journal"
                },
                {
                    name: "Meu perfil",
                    slug: "LmsUserProfile",
                    icon: "bi bi-person-fill"
                },
                {
                    name: "Book do aluno",
                    slug: "LmsStudentBook",
                    icon: "bi bi-book-half"
                }
            ]
        },
        LmsFeedSocial: {
            pageName: "Feed Social",
            navigationTitle: "Minhas Comunidades",
            navigation: [
                // {
                //     name: "Fórum de discussão",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Ranking",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Turmas",
                //     slug: "",
                //     icon: ""
                // },
                {
                    name: "Minhas Publicações",
                    slug: "LmsMyFeedSocial",
                    icon: "bi bi-journal"
                },
                {
                    name: "Meu perfil",
                    slug: "LmsUserProfile",
                    icon: "bi bi-person-fill"
                },
                {
                    name: "Book do aluno",
                    slug: "LmsStudentBook",
                    icon: "bi bi-book-half"
                }
            ]
        },
        LmsMyFeedSocial: {
            pageName: "Meu Feed",
            navigationTitle: "Minhas Comunidades",
            navigation: [
                // {
                //     name: "Fórum de discussão",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Ranking",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Turmas",
                //     slug: "",
                //     icon: ""
                // },
                {
                    name: "Feed Social",
                    slug: "LmsFeedSocial",
                    icon: "bi bi-people-fill"
                },
                {
                    name: "Meu perfil",
                    slug: "LmsUserProfile",
                    icon: "bi bi-person-fill"
                },
                {
                    name: "Book do aluno",
                    slug: "LmsStudentBook",
                    icon: "bi bi-book-half"
                }
            ]
        },
        LmsMyJourney: {
            pageName: "Minha Jornada",
            navigationTitle: "Minha Jornada",
            navigation: []
        },
        LmsNotifications: {
            pageName: "Notificações",
            navigationTitle: "Minhas comunidades",
            navigation: [
                // {
                //     name: "Fórum de discussão",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Ranking",
                //     slug: "",
                //     icon: ""
                // },
                // {
                //     name: "Turmas",
                //     slug: "",
                //     icon: ""
                // },
                {
                   name: "Feed Social",
                   slug: "LmsFeedSocial",
                   icon: "bi bi-people-fill"
                },
            ]
        }
    },
    courseSelected: 0,
    coinsName: "Moedas",
    loading: false,
    notificationsList: [],
    routeHeader: ""
};