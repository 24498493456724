<template>
    <div class="row publish-feed">

        <div class="d-flex justify-content-between align-items-center">
            <div>
                <img v-if="!isMobile" :src="avatar" style="width: 64px; height: 64px; object-fit: cover; object-position: center; border-radius: 50%;">
                <span class="nickname">{{ nickname }}</span>
            </div>
            <div class="editor-element-toolbar"></div>
        </div>


        <div style="display: none;" class="justify-content-between align-items-center" id="preview-element">
            <div class="post-img-container" id="image-container" style="display: none;">
                <div class="post-img-back" id="image-preview-back"></div>
                <img class="post-img" id="image-preview">
            </div>

            <div class="post-img-container" id="video-container" style="display: none;">
                <video controls class="post-img" id="video-preview"></video>
            </div>

            <div class="post-img-container" id="document-container" style="display: none; padding: 0 !important;">
                <div class="post-document d-flex justify-content-between" id="document-preview">
                    <div class="d-flex align-items-center">
                        <svg width="42" height="42" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.9688 0C33.0938 0 34.0312 0.40625 34.7812 1.21875C35.5938 1.96875 36 2.90625 36 4.03125V31.9688C36 33.0938 35.5938 34.0625 34.7812 34.875C34.0312 35.625 33.0938 36 31.9688 36H4.03125C2.90625 36 1.9375 35.625 1.125 34.875C0.375 34.0625 0 33.0938 0 31.9688V4.03125C0 2.90625 0.375 1.96875 1.125 1.21875C1.9375 0.40625 2.90625 0 4.03125 0H31.9688ZM22.0312 28.0312V24H7.96875V28.0312H22.0312ZM28.0312 19.9688V16.0312H7.96875V19.9688H28.0312ZM28.0312 12V7.96875H7.96875V12H28.0312Z" fill="var(--buttons_color)"/>
                        </svg>

                        <div style="margin-left: 15px;">
                            <span id="document-preview-name">Nome_do_documento.pdf</span>
                            <br>
                            <span id="document-preview-size">1,36 MB</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center">
                        <button type="button" class="button-action" @click="editCurrent('document')">
                            <svg width="13" height="13" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.8125 2.6875L10.5938 3.90625L8.09375 1.40625L9.3125 0.1875C9.4375 0.0625 9.59375 0 9.78125 0C9.96875 0 10.125 0.0625 10.25 0.1875L11.8125 1.75C11.9375 1.875 12 2.03125 12 2.21875C12 2.40625 11.9375 2.5625 11.8125 2.6875ZM0 9.5L7.375 2.125L9.875 4.625L2.5 12H0V9.5Z" fill="white"/>
                            </svg>
                        </button>

                        <button type="button" class="button-action" @click="setCurrentType('document')">
                            <svg width="13" height="13" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.65625 1.28125L5.9375 5L9.65625 8.71875L8.71875 9.65625L5 5.9375L1.28125 9.65625L0.34375 8.71875L4.0625 5L0.34375 1.28125L1.28125 0.34375L5 4.0625L8.71875 0.34375L9.65625 1.28125Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="post-document-container flex-column" style="display: none; padding: 0 !important;" id="link-container">
                <div id="link-preview" class="post-link">
                    <div id="link-preview-back" class="post-img-back" style="background: url('https://cdn.discordapp.com/attachments/931328487011270696/1152217771069935676/WhatsApp_Image_2023-09-15_at_09.12.07.jpeg');"></div>
                    <img id="link-preview-image" style="position: relative; z-index: 0;" class="post-link-img" src="https://cdn.discordapp.com/attachments/931328487011270696/1152217771069935676/WhatsApp_Image_2023-09-15_at_09.12.07.jpeg" alt="">

                    <button type="button" class="button-action" style="position: absolute; top: 10px; right: 10px;" @click="closeLinkUploader(true)">
                        <svg width="13" height="13" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.65625 1.28125L5.9375 5L9.65625 8.71875L8.71875 9.65625L5 5.9375L1.28125 9.65625L0.34375 8.71875L4.0625 5L0.34375 1.28125L1.28125 0.34375L5 4.0625L8.71875 0.34375L9.65625 1.28125Z" fill="white"/>
                        </svg>
                    </button>
                </div>

                <div class="d-flex justify-content-between" style="border-top: 1px solid #E1E1E1; background: #F6F6F6; z-index: 2; padding: 15px 25px; margin: 0 !important;">
                    <div style="max-width: 90%; text-wrap: wrap;">
                        <span class="link-url" id="link-preview-url"></span>
                        <br>
                        <span class="link-title" id="link-preview-title"></span>
                        <br>
                        <span class="link-desc" id="link-preview-desc"></span>
                    </div>
                    <div class="open-link-preview">
                        <svg @click="openLink" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0156 0H18V6.98438H15.9844V3.42188L6.1875 13.2188L4.78125 11.8125L14.5781 2.01562H11.0156V0ZM15.9844 15.9844V9H18V15.9844C18 16.5156 17.7969 16.9844 17.3906 17.3906C16.9844 17.7969 16.5156 18 15.9844 18H2.01562C1.45312 18 0.96875 17.8125 0.5625 17.4375C0.1875 17.0312 0 16.5469 0 15.9844V2.01562C0 1.45312 0.1875 0.984375 0.5625 0.609375C0.96875 0.203125 1.45312 0 2.01562 0H9V2.01562H2.01562V15.9844H15.9844Z" fill="currentColor"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <div :class="editorId"></div>
        <div class="counter">0/500</div>

        <div class="d-flex" :class="{'justify-content-between': !isMobile}" style="margin-top: 14px">

            <div class="d-flex select-list">
                <input type="file" @change="handleUploaded('image')" accept="image/*" class="d-none" name="image-file" id="image-type">
                <input type="file" @change="handleUploaded('video')" accept="video/*" class="d-none" name="video-file" id="video-type">
                <input type="file" @change="handleUploaded('document')" accept=".pdf, .csv, .xls, .xlsx, .pptx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" class="d-none" name="document-file" id="document-type">

                <div class="link-uploader" :class="{'d-flex': showLinkUploader, 'd-none': !showLinkUploader}">
                    <div class="d-flex flex-column center-uploader">
                        <span class="label-title">Compartilhe um Link</span>
                        <span class="label-desc">Compartilhe notícias e links interessantes. Não aceitamos links suspeitos.</span>
                        <input @change="handleUploaded('link')" class="input-link" type="text" name="link-file" id="link-type">
                    </div>
                </div>

                <div @click.stop="setCurrentType('image')" id="foto-selector" class="selector d-flex align-items-center" :class="{'actual': currentType == 'image'}">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.24609 13.1719L3.19922 18.4219H19.4961L14.2461 11.4219L10.1992 16.6719L7.24609 13.1719ZM21.8477 19.5703C21.8477 20.1901 21.6107 20.737 21.1367 21.2109C20.6628 21.6849 20.1159 21.9219 19.4961 21.9219H3.19922C2.57943 21.9219 2.03255 21.6849 1.55859 21.2109C1.08464 20.737 0.847656 20.1901 0.847656 19.5703V3.27344C0.847656 2.65365 1.08464 2.10677 1.55859 1.63281C2.03255 1.15885 2.57943 0.921875 3.19922 0.921875H19.4961C20.1159 0.921875 20.6628 1.15885 21.1367 1.63281C21.6107 2.10677 21.8477 2.65365 21.8477 3.27344V19.5703Z" fill="currentColor"/>
                    </svg>
                    Foto
                </div>
                <div @click.stop="setCurrentType('video')" id="video-selector" class="selector d-flex align-items-center"  :class="{'actual': currentType == 'video'}">
                    <svg width="27" height="22" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.3477 0.0703125H23.9961V16.4219C23.9961 17.0417 23.7591 17.5885 23.2852 18.0625C22.8477 18.5365 22.319 18.7734 21.6992 18.7734H2.99609C2.3763 18.7734 1.82943 18.5365 1.35547 18.0625C0.917969 17.5885 0.699219 17.0417 0.699219 16.4219V2.42188C0.699219 1.80208 0.917969 1.25521 1.35547 0.78125C1.82943 0.307292 2.3763 0.0703125 2.99609 0.0703125H4.19922L6.49609 4.77344H9.99609L7.69922 0.0703125H9.99609L12.3477 4.77344H15.8477L13.4961 0.0703125H15.8477L18.1992 4.77344H21.6992L19.3477 0.0703125Z" fill="currentColor"/>
                    </svg>
                    Vídeo
                </div>
                <div @click.stop="setCurrentType('document')" id="document-selector" class="selector d-flex align-items-center"  :class="{'actual': currentType == 'document'}">
                    <svg width="17" height="22" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.4961 8.92188H17.9492L11.4961 2.52344V8.92188ZM14.9961 14.7734V12.4219H5.69922V14.7734H14.9961ZM14.9961 19.4219V17.0703H5.69922V19.4219H14.9961ZM12.6992 0.773438L19.6992 7.77344V21.7734C19.6992 22.3932 19.4622 22.9401 18.9883 23.4141C18.5143 23.8516 17.9674 24.0703 17.3477 24.0703H3.34766C2.72786 24.0703 2.18099 23.8516 1.70703 23.4141C1.23307 22.9401 0.996094 22.3932 0.996094 21.7734L1.05078 3.07031C1.05078 2.45052 1.26953 1.92188 1.70703 1.48438C2.18099 1.01042 2.72786 0.773438 3.34766 0.773438H12.6992Z" fill="currentColor"/>
                    </svg>
                    Documento
                </div>
                <div @click.stop="setCurrentType('link')" id="link-selector" class="selector d-flex align-items-center"  :class="{'actual': currentType == 'link'}">
                    <svg width="22" height="22" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.1992 0.570312C19.8034 0.570312 21.1706 1.15365 22.3008 2.32031C23.431 3.45052 23.9961 4.81771 23.9961 6.42188C23.9961 8.02604 23.431 9.41146 22.3008 10.5781C21.1706 11.7083 19.8034 12.2734 18.1992 12.2734H13.4961V10.0312H18.1992C19.1836 10.0312 20.0221 9.6849 20.7148 8.99219C21.444 8.26302 21.8086 7.40625 21.8086 6.42188C21.8086 5.4375 21.444 4.59896 20.7148 3.90625C20.0221 3.17708 19.1836 2.8125 18.1992 2.8125H13.4961V0.570312H18.1992ZM7.69922 7.57031V5.27344H16.9961V7.57031H7.69922ZM3.92578 3.90625C3.23307 4.59896 2.88672 5.4375 2.88672 6.42188C2.88672 7.40625 3.23307 8.26302 3.92578 8.99219C4.65495 9.6849 5.51172 10.0312 6.49609 10.0312H11.1992V12.2734H6.49609C4.89193 12.2734 3.52474 11.7083 2.39453 10.5781C1.26432 9.41146 0.699219 8.02604 0.699219 6.42188C0.699219 4.81771 1.26432 3.45052 2.39453 2.32031C3.52474 1.15365 4.89193 0.570312 6.49609 0.570312H11.1992V2.8125H6.49609C5.51172 2.8125 4.65495 3.17708 3.92578 3.90625Z" fill="currentColor"/>
                    </svg>
                    Link
                </div>
            </div>
            <div v-if="!isMobile">
                <button @click.stop="publishPost" class="btn btn-publish">
                    Publicar
                </button>
            </div>
        </div>
        <div v-if="isMobile">
            <button @click.stop="publishPost" class="btn btn-publish">
                Publicar
            </button>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
name: "LmsFeedPublish",
props: ['loading','newPost', 'isMobile', 'isCommunity'],
data() {
    return {
      editor: null,
      editorId: 'editor-element',
      currentType: null,
      showLinkUploader: false
    };
},
beforeMount() {

},
mounted() {
    let root_ = this;


    const editorElement = document.querySelector(`.${root_.editorId}`);

    window.DecoupledEditor
    .create(editorElement, {
        toolbar: ['bold', 'italic', 'underline'],
    })
    .then(editor => {
        const toolbarContainer = document.querySelector(`.${root_.editorId}-toolbar`);
        const toolbarElement = editor.ui.view.toolbar.element;
        toolbarContainer.appendChild(toolbarElement);

        editor.editing.view.document.on('paste', ( evt, data ) => {
            const content = editor.getData();
            const charCount = root_.countVisibleCharacters(content);

            const remainingChars = 500 - charCount;

            if (data.dataTransfer && data.dataTransfer.getData('text/plain')) {
                let pastedText = data.dataTransfer.getData('text/plain');
                if (pastedText.length > remainingChars) {
                    pastedText = pastedText.slice(0, remainingChars);
                }

                // We need to manually insert the text to the editor because we modified pasted content
                evt.stop();
                data.preventDefault();
                editor.model.change( writer => {
                    editor.model.insertContent( writer.createText(pastedText), editor.model.document.selection );
                });
            }
        });

        editor.editing.view.document.on('keydown', ( evt, data ) => {
            const content = editor.getData();
            const charCount = root_.countVisibleCharacters(content);

            if ((charCount >= 500 && ![8, 46].includes(data.keyCode)) || (data.keyCode === 13 && charCount >= 499)) {
                data.preventDefault();
            }
        });

        editor.model.document.on('change:data', () => {
            const content = editor.getData();
            const charCount = root_.countVisibleCharacters(content);
            const counterElement = document.querySelector('.counter');
            const editorContainer = document.querySelector('.editor-element');

            counterElement.textContent = `${charCount}/500`;

            // Check if the number of characters is within limits
            if (charCount >= 500) {
                counterElement.style.color = 'red';
                editorContainer.style.border = '1px solid red !important';
            } else {
                counterElement.style.color = '#ccc';
                editorContainer.style.border = 'initial';
            }
        });
        
        root_.editor = editor;
    })
    .catch(error => {
        console.error(error);
    });




},
methods: {
    handleLoading(status) {
        this.loading(status);
    },
    closeLinkUploader(force) {
        let root_ = this;
        let input = document.getElementById(`link-type`);
        if(force) {
            input.value='';
            root_.setCurrentType('link');
        } else {
            if(input.value) {
                root_.currentType = 'link';
                root_.showLinkUploader = false;
            } else {
                root_.currentType = null;
                root_.showLinkUploader = false;
            }
        }
    },
    openLink(){
        let input = document.getElementById(`link-type`);

        if(input.value) {
            window.open(input.value, '_blank');
        }
    },
    editCurrent(type) {
        let input = document.getElementById(`${type}-type`);
        if(type != 'link') {
            input.click();
        }
    },
    handleUploaded(type) {
        let root_ = this;
        const div = document.getElementById('preview-element');
        const input = document.getElementById(`${type}-type`);
        const imageBack = document.getElementById('image-preview-back')
        const imagePreview = document.getElementById('image-preview')
        const videoPreview = document.getElementById('video-preview')
        const documentName = document.getElementById('document-preview-name')
        const documentSize = document.getElementById('document-preview-size')
        const linkURL = document.getElementById('link-preview-url')
        const linkTITLE = document.getElementById('link-preview-title')
        const linkDESC = document.getElementById('link-preview-desc')
        const linkIMAGE = document.getElementById('link-preview-image')
        const linkBack = document.getElementById('link-preview-back')
        root_.resetPreview();
        root_.handleLoading(true);

        if(root_.currentType) {
            document.getElementById(`${type}-container`).style.display = 'flex';
            if(type != 'link') {
                const file = input.files[0];
                const fileName = file.name;
                const fileSizeInMB = file.size / (1024 * 1024);

                if (file) {
                    const fileURL = URL.createObjectURL(file);

                    if(type == 'image'){
                        imageBack.style.backgroundImage = `url('${fileURL}')`;
                        imagePreview.src = fileURL;
                    } else if(type == 'video') {
                        videoPreview.src = fileURL;
                    } else if(type == 'document') {
                        if(root_.isMobile) {
                            documentName.innerHTML = `${fileName.substring(0, 2)}...`;
                        } else {
                            documentName.innerHTML = `${fileName}`;
                        }
                        documentSize.innerHTML = `${fileSizeInMB.toFixed(2)} MB`;
                    }

                    div.style.display = 'flex';
                    root_.handleLoading(false);
                } else {
                    div.style.display = 'none';
                    root_.handleLoading(false);
                }
            } else {
                const parsedURL = new URL(input.value);
                const siteURL = parsedURL.origin;
                let data;

                let payload = {
                    url: input.value,
                }

                root_.$store.dispatch('Feed/getMetaData', payload).then( response => {
                    console.log('Publish Post => ', response);

                    data = {
                        title: response.data.title,
                        description: response.data.description,
                        imageUrl: response.data.image ?? 'https://cdn.discordapp.com/attachments/931328487011270696/1156341848567185508/imageonline-co-placeholder-image.png',
                        siteURL: siteURL
                    }
                    linkURL.innerHTML = `${data.siteURL}`;
                    linkDESC.innerHTML = `${data.description}`;
                    linkTITLE.innerHTML = `${data.title}`;
                    linkBack.style.backgroundImage = `url(${data.imageUrl})`;
                    linkIMAGE.src = `${data.imageUrl}`;

                    root_.handleLoading(false);
                }).catch( error => {
                    error;
                    data = {
                        title: '',
                        description: '',
                        imageUrl: 'https://cdn.discordapp.com/attachments/931328487011270696/1156341848567185508/imageonline-co-placeholder-image.png',
                        siteURL: siteURL
                    }
                    linkURL.innerHTML = `${data.siteURL}`;
                    linkDESC.innerHTML = `${data.description}`;
                    linkTITLE.innerHTML = `${data.title}`;
                    linkBack.style.backgroundImage = `url(${data.imageUrl})`;
                    linkIMAGE.src = `${data.imageUrl}`;
                    root_.handleLoading(false);
                });



                div.style.display = 'flex';
            }
        } else {
            root_.handleLoading(false);
            div.style.display = 'none';
        }
    },
    resetPreview() {
        let root_ = this;
        let types = ['image', 'video', 'document', 'link']

        types.forEach(type => {
            document.getElementById(`${type}-container`).style.display = 'none';
        });

        root_.showLinkUploader = false;

        const div = document.getElementById('preview-element');
        const imageBack = document.getElementById('image-preview-back')
        const imagePreview = document.getElementById('image-preview')
        const videoPreview = document.getElementById('video-preview')
        const documentName = document.getElementById('document-preview-name')
        const documentSize = document.getElementById('document-preview-size')
        const linkURL = document.getElementById('link-preview-url')
        const linkTITLE = document.getElementById('link-preview-title')
        const linkDESC = document.getElementById('link-preview-desc')
        const linkIMAGE = document.getElementById('link-preview-image')
        const linkBack = document.getElementById('link-preview-back')
        div.style.display = 'none';
        imageBack.style.backgroundImage = ``;
        linkBack.style.backgroundImage = ``;
        linkIMAGE.src = ``;
        imagePreview.src = ``;
        videoPreview.src = ``;
        linkURL.innerHTML = ``;
        linkDESC.innerHTML = ``;
        linkTITLE.innerHTML = ``;
        documentName.innerHTML = ``;
        documentSize.innerHTML = ``;
    },
    setCurrentType(type) {
        let root_ = this;
        if(type == root_.currentType) {
            if(type == 'link' && document.getElementById(`link-type`).value) {
                root_.showLinkUploader = !root_.showLinkUploader;
            } else {
                root_.currentType = null;
                root_.resetPreview();
            }
        } else {
            root_.resetPreview();
            root_.currentType = type;
            let input = document.getElementById(`${root_.currentType}-type`)
            input.value='';
            if(root_.currentType != 'link') {
                input.click();
            } else {
                root_.showLinkUploader = true;
            }
        }

    },
    countVisibleCharacters(htmlContent) {
        let div = document.createElement('div');
        div.innerHTML = htmlContent;
        return div.innerText.length;
    },
    resetPublish() {
        let root_ = this;
        root_.editor.setData('');
        root_.currentType = null;
        root_.resetPreview();
    },
    publishPost() {
        let root_ = this;
        let type;
        root_.handleLoading(true);
        if(root_.currentType == 'document') {
            type = 'file';
        } else {
            type = root_.currentType ? root_.currentType : 'image';
        }
        let input = document.getElementById(`${root_.currentType}-type`)
        if(type != 'link') {
            if(input && input.files && input.files.length > 0) {
                input = input.files[0];
            } else {
                input = '';
            }
        } else {
            input = input.value;
        }

        let payload = {
            detail: root_.editor.getData(),
            file_type: type,
            photo_url: input,
            lms_slug: root_.$route.params.lmsSlug,
        }

        if(root_.isCommunity) {
            payload.community_slug = root_.$route.params.communitySlug;
        }

        root_.$store.dispatch('Feed/publishPost', payload).then( response => {
            console.log('Publish Post => ', response);
            root_.newPost();
            root_.resetPublish();
        }).catch( error => {
            error;
            root_.handleLoading(false);
        });
    }
},
computed:{
    ...mapGetters([
        'avatar', 'nickname'
    ])
},
beforeDestroy() {

}

}

</script>
  
<style lang="scss">
.link-uploader {
    z-index: 2;
    position: absolute;
    right: 0;
    bottom: 100%;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08);

    .center-uploader {
        display: flex;
        justify-content: center;
        padding: 18px;
        gap: 10px;
        color: #706F6F;
        font-family: Hanken Grotesk;
        line-height: normal;
        font-style: normal;

        .label-title {
            font-size: 16px;
            font-weight: 700;            
        }

        .label-desc {
            font-size: 16px;
            font-weight: 400;    
        }

        input {
            padding: 10px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #E1E1E1;
        }
    }
}
#link-container {
    padding: 0 !important;
}
.publish-feed {
    width: 100%;
    border: 1px solid #e1e1e1;
    border-radius: 30px;
    padding: 35px 88px;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);

    #document-preview-name {
        color: #706F6F;
        font-family: Hanken Grotesk;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    #document-preview-size {
        color: #706F6F;
        font-family: Hanken Grotesk;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .button-action {
        display: flex;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-left: 10px;
        background: rgba(0, 0, 0, 0.50);
        border: 1px solid #00000080;

        &:hover {
            background: rgba(0, 0, 0, 0.70);
        }
    }

    .post-img-container {
        position: relative;
        max-width: 100%;
        min-width: 100%;
        margin: 10px 0;
        max-height: 420px;
        overflow: hidden; /* Certifica-se de que o desfoque fique contido dentro do contêiner */
    }

    .post-document-container {
        position: relative;
        max-width: 100%;
        min-width: 100%;
        margin: 10px 0;
        overflow: hidden;
        border: 1px solid #E1E1E1;

        .open-link-preview {
            svg {
                color: #B1B1B1;
                &:hover {
                    color: var(--buttons_color);
                    cursor: pointer;
                }
            }
        }

        .link-title {
            color: #706F6F;
            font-family: Hanken Grotesk;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .link-url, .link-desc {
            color: #706F6F;
            font-family: Hanken Grotesk;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .post-link {
            max-width: 100%;
            min-width: 100%;

            .post-link-img {
                max-height: 300px !important;
                position: relative;
                width: 100%;
                height: auto;
                object-fit: scale-down;
                object-position: center;
            }
        }
    }

    .post-img {
        position: relative;
        z-index: 1; /* Garante que a imagem fique na frente do fundo desfocado */
        width: 100%;
        height: auto;
        object-fit: scale-down;
        object-position: center;
        max-height: 420px;
    }

    .post-document {
        padding: 20px 30px;
        border: 1px solid #E1E1E1;
        border-radius: 10px;
        max-width: 100%;
        min-width: 100%;
    }


    .post-img-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: blur(12px); /* Aplica o desfoque apenas ao fundo */
        z-index: 0; /* Garante que o fundo desfocado fique atrás da imagem */
    }

    .btn-publish {
        display: flex;
        padding: 10px 28px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 30px;
        background: var(--buttons_color);
        color: var(--fonts_color);
        line-height: 24px;
        transition: background 0.3s, color 0.3s, box-shadow 0.3s;

        &.disabled {
            background-color: #B1B1B1;
            color: #696A70;

            &:hover {
                background-color: #B1B1B1;
                color: #696A70;
                cursor: not-allowed;
            }
        }

        &:hover {
            background: var(--buttons_color_2nd);
            color: var(--fonts_color_2nd);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }
    }
    .selector {
        font-family: Hanken Grotesk;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #B1B1B1;
        margin-right: 20px;

        svg {
            fill: #B1B1B1;
            margin-right: 5px;
        }

        &.disable {
            color: #E1E1E1 !important;

            svg {
                fill: #E1E1E1 !important;
            }

            &:hover {
                color: #E1E1E1 !important;
                background-color: transparent !important;
                cursor: not-allowed;

                svg {
                    fill: #E1E1E1 !important;
                }
            }
        }

        &:hover {
            cursor: pointer;
            color: var(--buttons_color);
            svg {
                fill: var(--buttons_color);
            }
        }

        &.actual {

            color: var(--buttons_color);
            svg {
                fill: var(--buttons_color);
            }
            &:hover {
                color: #B1B1B180;
                svg {
                    fill: #B1B1B180;
                }
            }
        }
    }
    .counter {
        margin-top: 5px;
        color: #706F6F;
        font-family: Hanken Grotesk;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .nickname {
        color: #706F6F;
        font-family: Hanken Grotesk;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 10px;
    }

    .editor-element{
        margin-top: 10px;
        border: 1px solid #e1e1e1 !important;
        border-radius: 9px !important;
        min-height: 80px;
        background: #FFF;
        display: flex;
        padding: 18px 30px;
        max-width: 100%;
        overflow-wrap: break-word;  /* Quebra as palavras para a próxima linha */
        color: #706F6F;
        font-family: Hanken Grotesk;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        
        .ck.ck-content {
            white-space: pre-wrap;
        }
    }
    .editor-element-toolbar {
        .ck.ck-toolbar {
            background: none !important;
            border: none !important;
            background: none !important;
        }
        .ck.ck-button {
            box-shadow: 0 0 15px 1px rgba(105, 106, 112, 0.15) !important;
            color: #706F6F;
            background-color: #FFF !important;
            margin: 0 8px !important;
            cursor: pointer !important;
        }

        .ck.ck-button:hover {
            background-color: #706F6F !important;
            color: #FFF;
            box-shadow: none !important;
        }
    }
}


.select-list {
    position: relative;
}

@media only screen and (max-width: 1400px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {

}
@media only screen and (max-width: 768px) {
.publish-feed {
    .select-list {
        
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        width: 100%;
        .selector {
            margin-right: 0px !important;
            margin-bottom: 10px;

            display: flex !important;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
}
}
@media only screen and (max-width: 480px) {
  
}



</style>